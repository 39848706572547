export const Provinces = [
  {
    label: "Agrigento",
    value: "AG",
    regione: "Sicilia",
  },
  {
    label: "Alessandria",
    value: "AL",
    regione: "Piemonte",
  },
  {
    label: "Ancona",
    value: "AN",
    regione: "Marche",
  },
  {
    label: "Arezzo",
    value: "AR",
    regione: "Toscana",
  },
  {
    label: "Ascoli Piceno",
    value: "AP",
    regione: "Marche",
  },
  {
    label: "Asti",
    value: "AT",
    regione: "Piemonte",
  },
  {
    label: "Avellino",
    value: "AV",
    regione: "Campania",
  },
  {
    label: "Bari",
    value: "BA",
    regione: "Puglia",
  },
  {
    label: "Barletta-Andria-Trani",
    value: "BT",
    regione: "Puglia",
  },
  {
    label: "Belluno",
    value: "BL",
    regione: "Veneto",
  },
  {
    label: "Benevento",
    value: "BN",
    regione: "Campania",
  },
  {
    label: "Bergamo",
    value: "BG",
    regione: "Lombardia",
  },
  {
    label: "Biella",
    value: "BI",
    regione: "Piemonte",
  },
  {
    label: "Bologna",
    value: "BO",
    regione: "Emilia-Romagna",
  },
  {
    label: "Bolzano/Bozen",
    value: "BZ",
    regione: "Trentino-Alto Adige/Südtirol",
  },
  {
    label: "Brescia",
    value: "BS",
    regione: "Lombardia",
  },
  {
    label: "Brindisi",
    value: "BR",
    regione: "Puglia",
  },
  {
    label: "Cagliari",
    value: "CA",
    regione: "Sardegna",
  },
  {
    label: "Caltanissetta",
    value: "CL",
    regione: "Sicilia",
  },
  {
    label: "Campobasso",
    value: "CB",
    regione: "Molise",
  },
  {
    label: "Carbonia-Iglesias",
    value: "CI",
    regione: "Sardegna",
  },
  {
    label: "Caserta",
    value: "CE",
    regione: "Campania",
  },
  {
    label: "Catania",
    value: "CT",
    regione: "Sicilia",
  },
  {
    label: "Catanzaro",
    value: "CZ",
    regione: "Calabria",
  },
  {
    label: "Chieti",
    value: "CH",
    regione: "Abruzzo",
  },
  {
    label: "Como",
    value: "CO",
    regione: "Lombardia",
  },
  {
    label: "Cosenza",
    value: "CS",
    regione: "Calabria",
  },
  {
    label: "Cremona",
    value: "CR",
    regione: "Lombardia",
  },
  {
    label: "Crotone",
    value: "KR",
    regione: "Calabria",
  },
  {
    label: "Cuneo",
    value: "CN",
    regione: "Piemonte",
  },
  {
    label: "Enna",
    value: "EN",
    regione: "Sicilia",
  },
  {
    label: "Fermo",
    value: "FM",
    regione: "Marche",
  },
  {
    label: "Ferrara",
    value: "FE",
    regione: "Emilia-Romagna",
  },
  {
    label: "Firenze",
    value: "FI",
    regione: "Toscana",
  },
  {
    label: "Foggia",
    value: "FG",
    regione: "Puglia",
  },
  {
    label: "Forlì-Cesena",
    value: "FC",
    regione: "Emilia-Romagna",
  },
  {
    label: "Frosinone",
    value: "FR",
    regione: "Lazio",
  },
  {
    label: "Genova",
    value: "GE",
    regione: "Liguria",
  },
  {
    label: "Gorizia",
    value: "GO",
    regione: "Friuli-Venezia Giulia",
  },
  {
    label: "Grosseto",
    value: "GR",
    regione: "Toscana",
  },
  {
    label: "Imperia",
    value: "IM",
    regione: "Liguria",
  },
  {
    label: "Isernia",
    value: "IS",
    regione: "Molise",
  },
  {
    label: "L'Aquila",
    value: "AQ",
    regione: "Abruzzo",
  },
  {
    label: "La Spezia",
    value: "SP",
    regione: "Liguria",
  },
  {
    label: "Latina",
    value: "LT",
    regione: "Lazio",
  },
  {
    label: "Lecce",
    value: "LE",
    regione: "Puglia",
  },
  {
    label: "Lecco",
    value: "LC",
    regione: "Lombardia",
  },
  {
    label: "Livorno",
    value: "LI",
    regione: "Toscana",
  },
  {
    label: "Lodi",
    value: "LO",
    regione: "Lombardia",
  },
  {
    label: "Lucca",
    value: "LU",
    regione: "Toscana",
  },
  {
    label: "Macerata",
    value: "MC",
    regione: "Marche",
  },
  {
    label: "Mantova",
    value: "MN",
    regione: "Lombardia",
  },
  {
    label: "Massa-Carrara",
    value: "MS",
    regione: "Toscana",
  },
  {
    label: "Matera",
    value: "MT",
    regione: "Basilicata",
  },
  {
    label: "Medio Campidano",
    value: "VS",
    regione: "Sardegna",
  },
  {
    label: "Messina",
    value: "ME",
    regione: "Sicilia",
  },
  {
    label: "Milano",
    value: "MI",
    regione: "Lombardia",
  },
  {
    label: "Modena",
    value: "MO",
    regione: "Emilia-Romagna",
  },
  {
    label: "Monza e della Brianza",
    value: "MB",
    regione: "Lombardia",
  },
  {
    label: "Napoli",
    value: "NA",
    regione: "Campania",
  },
  {
    label: "Novara",
    value: "NO",
    regione: "Piemonte",
  },
  {
    label: "Nuoro",
    value: "NU",
    regione: "Sardegna",
  },
  {
    label: "Ogliastra",
    value: "OG",
    regione: "Sardegna",
  },
  {
    label: "Olbia-Tempio",
    value: "OT",
    regione: "Sardegna",
  },
  {
    label: "Oristano",
    value: "OR",
    regione: "Sardegna",
  },
  {
    label: "Padova",
    value: "PD",
    regione: "Veneto",
  },
  {
    label: "Palermo",
    value: "PA",
    regione: "Sicilia",
  },
  {
    label: "Parma",
    value: "PR",
    regione: "Emilia-Romagna",
  },
  {
    label: "Pavia",
    value: "PV",
    regione: "Lombardia",
  },
  {
    label: "Perugia",
    value: "PG",
    regione: "Umbria",
  },
  {
    label: "Pesaro e Urbino",
    value: "PU",
    regione: "Marche",
  },
  {
    label: "Pescara",
    value: "PE",
    regione: "Abruzzo",
  },
  {
    label: "Piacenza",
    value: "PC",
    regione: "Emilia-Romagna",
  },
  {
    label: "Pisa",
    value: "PI",
    regione: "Toscana",
  },
  {
    label: "Pistoia",
    value: "PT",
    regione: "Toscana",
  },
  {
    label: "Pordenone",
    value: "PN",
    regione: "Friuli-Venezia Giulia",
  },
  {
    label: "Potenza",
    value: "PZ",
    regione: "Basilicata",
  },
  {
    label: "Prato",
    value: "PO",
    regione: "Toscana",
  },
  {
    label: "Ragusa",
    value: "RG",
    regione: "Sicilia",
  },
  {
    label: "Ravenna",
    value: "RA",
    regione: "Emilia-Romagna",
  },
  {
    label: "Reggio di Calabria",
    value: "RC",
    regione: "Calabria",
  },
  {
    label: "Reggio nell'Emilia",
    value: "RE",
    regione: "Emilia-Romagna",
  },
  {
    label: "Rieti",
    value: "RI",
    regione: "Lazio",
  },
  {
    label: "Rimini",
    value: "RN",
    regione: "Emilia-Romagna",
  },
  {
    label: "Roma",
    value: "RM",
    regione: "Lazio",
  },
  {
    label: "Rovigo",
    value: "RO",
    regione: "Veneto",
  },
  {
    label: "Salerno",
    value: "SA",
    regione: "Campania",
  },
  {
    label: "Sassari",
    value: "SS",
    regione: "Sardegna",
  },
  {
    label: "Savona",
    value: "SV",
    regione: "Liguria",
  },
  {
    label: "Siena",
    value: "SI",
    regione: "Toscana",
  },
  {
    label: "Siracusa",
    value: "SR",
    regione: "Sicilia",
  },
  {
    label: "Sondrio",
    value: "SO",
    regione: "Lombardia",
  },
  {
    label: "Taranto",
    value: "TA",
    regione: "Puglia",
  },
  {
    label: "Teramo",
    value: "TE",
    regione: "Abruzzo",
  },
  {
    label: "Terni",
    value: "TR",
    regione: "Umbria",
  },
  {
    label: "Torino",
    value: "TO",
    regione: "Piemonte",
  },
  {
    label: "Trapani",
    value: "TP",
    regione: "Sicilia",
  },
  {
    label: "Trento",
    value: "TN",
    regione: "Trentino-Alto Adige/Südtirol",
  },
  {
    label: "Treviso",
    value: "TV",
    regione: "Veneto",
  },
  {
    label: "Trieste",
    value: "TS",
    regione: "Friuli-Venezia Giulia",
  },
  {
    label: "Udine",
    value: "UD",
    regione: "Friuli-Venezia Giulia",
  },
  {
    label: "Valle d'Aosta/Vallée d'Aoste",
    value: "AO",
    regione: "Valle d'Aosta/Vallée d'Aoste",
  },
  {
    label: "Varese",
    value: "VA",
    regione: "Lombardia",
  },
  {
    label: "Venezia",
    value: "VE",
    regione: "Veneto",
  },
  {
    label: "Verbano-Cusio-Ossola",
    value: "VB",
    regione: "Piemonte",
  },
  {
    label: "Vercelli",
    value: "VC",
    regione: "Piemonte",
  },
  {
    label: "Verona",
    value: "VR",
    regione: "Veneto",
  },
  {
    label: "Vibo Valentia",
    value: "VV",
    regione: "Calabria",
  },
  {
    label: "Vicenza",
    value: "VI",
    regione: "Veneto",
  },
  {
    label: "Viterbo",
    value: "VT",
    regione: "Lazio",
  },
];
