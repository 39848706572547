import styled from "styled-components";
import { WrapContainer } from "../../components/wrapContainer";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { useDescriptiveBlockQuery } from "../../generated/graphql";

const Container = styled.div<{ center?: boolean }>`
  max-width: var(--page-max-width);
  width: 100%;
  padding-top: 2em;
  padding-bottom: 2em;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2em;
  img {
    max-width: 300px;
    height: auto;
  }
  p {
    max-width: 700px;
  }
  @media screen and (max-width: 1000px) {
    flex-direction: column;
    gap: 1em;
    img {
      max-width: 200px;
    }
  }
`;
export function ChiSiamoDesc() {
  const [{ data }] = useDescriptiveBlockQuery({
    variables: { id: "4Y60yHnDzrsmqPpUpU4aRJ" },
  });

  return (
    <WrapContainer grey>
      <Container>
        <img src="/img/matteo.webp"></img>

        {documentToReactComponents(data?.descriptiveBlock?.description?.json)}
        <img src="/img/loris.webp"></img>
      </Container>
    </WrapContainer>
  );
}
