import styled from "styled-components";
import {
  useNewCarMutation,
  NewCarMutationVariables,
} from "../../../generated/graphql";
import React from "react";
import { Button, Input, Select, Text } from "@fluentui/react-components";
import { useBrandList } from "../brandList/brandListProvider";
import { useAutoEditor } from "./autoEditorProvider";
const Container = styled.div`
  margin-top: 3em;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  grid-column-gap: 1rem;
  grid-row-gap: 1rem;
  max-width: 800px;
`;

const Title = styled(Text)`
  grid-column: 1/-1;
`;

export function NewCar() {
  const [_result, mutation] = useNewCarMutation();
  const [inputData, setData] = React.useState<NewCarMutationVariables>({});
  const { setSelectedModel } = useAutoEditor();
  const { brandList } = useBrandList();
  function sendData() {
    mutation(inputData).then((res) => {
      setSelectedModel(res.data?.insert_models_one?.id);
    });
  }
  return (
    <Container>
      <Title>Nuova macchina</Title>
      <Select
        value={inputData.brand || "seleziona"}
        defaultValue="seleziona"
        onChange={(event, data) => setData({ ...inputData, brand: data.value })}
      >
        <option value="seleziona" disabled>
          Seleziona
        </option>
        {brandList.map((val) => (
          <option key={val.name}>{val.name}</option>
        ))}
      </Select>
      <Input
        placeholder="Modello"
        value={inputData.model || ""}
        onChange={(event, data) => setData({ ...inputData, model: data.value })}
      />
      <Input
        placeholder="Anticipo"
        value={inputData.down_payment}
        onChange={(event, data) =>
          setData({ ...inputData, down_payment: data.value })
        }
      ></Input>
      <Input
        placeholder="Macchina Cortesia"
        value={inputData.courtesy_car_price}
        onChange={(event, data) =>
          setData({ ...inputData, courtesy_car_price: data.value })
        }
      />
      <Input
        placeholder="Gomme"
        value={inputData.tyre_price}
        onChange={(event, data) =>
          setData({ ...inputData, tyre_price: data.value })
        }
      />
      <Button onClick={sendData}>Salva</Button>
    </Container>
  );
}
