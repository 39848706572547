import React from "react";
import { Header } from "./components/header";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { Home } from "./pages/home/home";
import { Manager } from "./pages/manager/manager";
import { FluentProvider, webLightTheme } from "@fluentui/react-components";
import { Client, Provider, cacheExchange, fetchExchange } from "urql";
import { CarPage } from "./pages/carPage/carPage";
import { Footer } from "./components/footer";
import { WhatIsRent } from "./pages/whatIsRent/whatIsRent";
import { ChiSiamo } from "./pages/chiSiamo/chiSiamo";
import { Furgoni } from "./pages/furgoni/furgoni";
import { Fisco } from "./pages/fisco/fisco";
import { Contacts } from "./pages/contacts/contacts";
import { Preventivo } from "./pages/preventivo/preventivo";
import { AllOffers } from "./pages/allOffers/allOffers";
import { Login } from "./pages/login/login";
function RedirectHome() {
  window.location.href = "/";
  return null;
}
function RedirectContatti() {
  window.location.href = "/contatti";
  return null;
}
const router = createBrowserRouter([
  {
    path: "/",
    element: (
      <>
        <Header />
        <Home />
        <Footer />
      </>
    ),
  },
  {
    path: "/manager",
    element: (
      <>
        <Manager />
      </>
    ),
  },
  {
    path: "/carprice/:id",
    element: (
      <>
        <Header />
        <CarPage />
        <Footer />
      </>
    ),
  },
  {
    path: "/noleggio/",
    element: (
      <>
        <Header />
        <WhatIsRent />
        <Footer />
      </>
    ),
  },
  {
    path: "/chisiamo/",
    element: (
      <>
        <Header />
        <ChiSiamo />
        <Footer />
      </>
    ),
  },
  {
    path: "/furgoni/",
    element: (
      <>
        <Header />
        <Furgoni />
        <Footer />
      </>
    ),
  },
  {
    path: "/fisco/",
    element: (
      <>
        <Header />
        <Fisco />
        <Footer />
      </>
    ),
  },
  {
    path: "/contatti/",
    element: (
      <>
        <Header />
        <Contacts />
        <Footer />
      </>
    ),
  },
  {
    path: "/preventivo/",
    element: (
      <>
        <Header />
        <Preventivo />
        <Footer />
      </>
    ),
  },
  {
    path: "/offerte/",
    element: (
      <>
        <Header />
        <AllOffers />
        <Footer />
      </>
    ),
  },
  {
    path: "/login/",
    element: (
      <>
        <Login />
      </>
    ),
  },
  {
    path: "/copia-di-contatti",
    element: (
      <>
        <RedirectContatti />
      </>
    ),
  },
]);

function App() {
  const password = localStorage.getItem("password");

  const headers = password
    ? {
        "x-hasura-admin-secret": password,
      }
    : [];
  const client = new Client({
    url: process.env["REACT_APP_HASURA_ENDPOINT"] as string,
    exchanges: [cacheExchange, fetchExchange],
    fetchOptions: () => {
      return {
        headers,
      };
    },
  });

  return (
    <div className="App">
      <Provider value={client}>
        <FluentProvider theme={webLightTheme}>
          <RouterProvider router={router} />
        </FluentProvider>
      </Provider>
    </div>
  );
}

export default App;
