import styled from "styled-components";
import Dropdown from "../../components/dropDown";
import { RoundedButton } from "../../components/roundedButton";
import { SearchQueryVariables, useSearchQuery } from "../../generated/graphql";
import { useEffect, useState } from "react";

const Slider = styled.div`
  background-image: url("/img/slider/affidati.webp");

  background-repeat: no-repeat;
  background-size: cover;
  background-position: left;
`;

const FindCarContainer = styled.div`
  display: flex;
  width: 100%;
  background-color: #f4f3f3;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  gap: 10px;
  h3 {
    text-align: center;
    width: 100%;
  }
  ${RoundedButton} {
    margin-top: 10px;
  }
`;
const Container = styled.div`
  display: grid;
  grid-template-columns: 300px 1fr;
  grid-column-gap: 50px;
  @media screen and (max-width: 800px) {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr;
    grid-column-gap: 0px;
    ${FindCarContainer} {
      padding: 0px;
      padding-bottom: 2em;
      grid-row: 2;
    }
  }
`;

export function HomeSlider() {
  const [searchParam, setSearchParam] = useState<SearchQueryVariables>({
    furgone: false,
    brand: undefined,
  });
  const [selectedCar, setSelectedCar] = useState<string>();
  const [{ data }, refresh] = useSearchQuery({ variables: searchParam });

  return (
    <Container>
      <FindCarContainer>
        <h3>TROVA LA TUA AUTO</h3>
        <Dropdown
          placeholder="Auto/Furgone"
          value={searchParam.furgone ? "true" : "false"}
          onChange={(event) => {
            setSearchParam({
              brand: undefined,
              furgone: event.target.value === "true",
            });
            setSelectedCar(undefined);
          }}
        >
          <option key="vanfalse" value="true">
            Furgone
          </option>
          <option key="vantrue" value="false">
            Auto
          </option>
        </Dropdown>

        <Dropdown
          placeholder="Scegli la marca"
          value={searchParam.brand || "placeholder"}
          onChange={(event) => {
            setSearchParam({
              ...searchParam,
              brand: event.target.value,
            });
            setSelectedCar(undefined);
          }}
        >
          {data?.brands.map((brand) => (
            <option key={"brand" + brand.name} value={brand.name}>
              {brand.name}
            </option>
          ))}
        </Dropdown>

        <Dropdown
          placeholder="Scegli il modello"
          value={selectedCar || "placeholder"}
          onChange={(event) => setSelectedCar(event.target.value)}
        >
          {data?.models.map((model) => (
            <option key={"model" + model} value={model.id}>
              {model.model_name}
            </option>
          ))}
        </Dropdown>
        <a href={"/carprice/" + selectedCar}>
          <RoundedButton disabled={selectedCar == undefined} className="solid">
            Vai alla macchina
          </RoundedButton>
        </a>
      </FindCarContainer>
      <Slider></Slider>
    </Container>
  );
}
