export const distanceMarks = [
  {
    value: 10000,
    label: "10.000",
  },
  {
    value: 20000,
    label: "20.000",
  },
  {
    value: 30000,
    label: "30.000",
  },
];

export const durationsMarks = [
  {
    value: 24,
    label: "24",
  },
  {
    value: 36,
    label: "36",
  },
  {
    value: 48,
    label: "48",
  },
  {
    value: 60,
    label: "60",
  },
];
