import { Text } from "@fluentui/react-components";
import { useFormContext } from "react-hook-form";
import styled from "styled-components";
import { useAutoEditor } from "./autoEditorProvider";
const Container = styled.div`
  margin-top: 2em;
  display: grid;
  grid-template-columns: 90px 90px 90px 90px 90px 90px 90px;
  grid-column-gap: 1rem;
  grid-row-gap: 0.5rem;
`;
const Divider = styled.div`
  grid-column: 1 / -1;
  border-bottom: 1px solid black;
`;

export function PricesList() {
  const { modelDetail } = useAutoEditor();
  const prices = modelDetail?.prices;

  const { register } = useFormContext(); // retrieve all hook methods

  if (!prices) return null;

  return (
    <Container>
      <Text>
        <b>MESI</b>
      </Text>
      <Text>
        <b>KM</b>
      </Text>

      <Text>
        <b>ANTICIPO</b>
      </Text>
      <Text>
        <b>RATA</b>
      </Text>
      <Text>
        <b>GOMME</b>
      </Text>
      <Text>
        <b>MACCHINA CORTESIA</b>
      </Text>
      <Text>
        <b>PREFERITO</b>
      </Text>

      {prices.map((val, index) => (
        <>
          {index % 3 === 0 && <Divider key={"div" + index} />}
          <Text key={"rental_durationd" + index}>{val.rental_duration}</Text>
          <Text key={"rental_distance" + index}>{val.rental_distance}</Text>
          <input
            key={"down_payment" + index}
            {...register(`prices.${index}.down_payment`)}
          />
          <input
            key={"monthly_payment" + index}
            {...register(`prices.${index}.monthly_payment`)}
          ></input>
          <input
            key={"tyres_price" + index}
            {...register(`prices.${index}.tyres_price`)}
          ></input>
          <input
            key={"courtesy_car_price" + index}
            {...register(`prices.${index}.courtesy_car_price`)}
          />

          <input
            key={"favorite" + index}
            type="checkbox"
            {...register(`prices.${index}.favorite`)}
          />
        </>
      ))}
    </Container>
  );
}
