import { NewsLetter } from "../../components/newsLetter";
import { RoundedInfo } from "../../components/roundedInfo";
import { AllOffersGrid } from "./allOffersGrid";

export function AllOffers() {
  return (
    <>
      <AllOffersGrid />
      <NewsLetter />
      <RoundedInfo id="2XKs2NYcToqGFcpTB9U3Yz"></RoundedInfo>
    </>
  );
}
