import { Input } from "@fluentui/react-components";
import styled from "styled-components";
import { RoundedButton } from "../../components/roundedButton";
import { useState } from "react";

export function Login() {
  const [password, setPassword] = useState<string | undefined>();
  function onSubmit() {
    if (!password) return;
    localStorage.setItem("password", password);
    window.location.href = "/manager";
  }
  return (
    <Container>
      Password
      <Input onChange={(val) => setPassword(val.target.value)} />
      <RoundedButton className="solid" onClick={onSubmit}>
        LOGIN
      </RoundedButton>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  align-items: center;
  height: 100vh;
  gap: 20px;
`;
