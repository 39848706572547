import { faClose, faHouse } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import styled, { css } from "styled-components";

const MenuContainer = styled.div<{ active?: boolean }>`
  width: 0;
  height: 100vh;
  background-color: #d9d9d9;
  position: fixed;
  transition: width 0.5s;
  top: 0;

  right: 0;
  z-index: 100;

  transition: width 0.5s;

  text-transform: uppercase;
  ${(props) =>
    props.active &&
    css`
      opacity: 1;
      width: 100%;
    `}
`;

const Content = styled.div`
  padding: 0 1em;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  gap: 1.5em;
  a {
    border-bottom: 1px solid black;
  }
`;

const TopBar = styled.div`
  padding: 2em 1em;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;
export function MobileMenu(props: { active?: boolean; onClose: () => void }) {
  return (
    <MenuContainer {...props}>
      <TopBar>
        <a href="/">
          <FontAwesomeIcon icon={faHouse} size="2x" color="#be1522" />
        </a>
        <FontAwesomeIcon icon={faClose} size="2x" onClick={props.onClose} />
      </TopBar>
      <Content>
        <a href="/noleggio">
          <div>Noleggio a lungo termine</div>
        </a>
        <a href="/chisiamo">
          <div>Chi siamo</div>
        </a>
        <a href="/offerte">
          <div>Auto</div>
        </a>
        <a href="/furgoni">
          <div>Furgoni</div>
        </a>
        <a href="/contatti">
          <div>Contatti</div>
        </a>
        <a href="https://subentronelnoleggio.it/">
          <div>Subentri</div>
        </a>
        <a href="/preventivo">
          <div>Richiedi un preventivo</div>
        </a>
      </Content>
    </MenuContainer>
  );
}
