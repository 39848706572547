import * as React from "react";
import { Delete24Regular } from "@fluentui/react-icons";

import {
  Button,
  DataGrid,
  DataGridBody,
  DataGridCell,
  DataGridHeader,
  DataGridHeaderCell,
  DataGridRow,
  TableColumnDefinition,
} from "@fluentui/react-components";
import { useBrandList } from "./brandListProvider";
import { BrandsQuery } from "../../../generated/graphql";
import { AddBrand } from "./addBrand";
type Item = BrandsQuery["brands"][0];
const brandColumns: TableColumnDefinition<Item>[] = [
  {
    columnId: "name",
    compare: (a, b) => 0,
    renderHeaderCell: () => "Nome",
    renderCell: (item) => item.name,
  },
  {
    columnId: "delete",
    compare: (a, b) => 0,
    renderHeaderCell: () => "",
    renderCell: (item) => <DeleteButton brand={item.name} />,
  },
];

function DeleteButton(props: { brand: string }) {
  const { deleteBrand } = useBrandList();
  return (
    <Button
      icon={<Delete24Regular />}
      appearance="subtle"
      color="danger"
      onClick={() => deleteBrand(props.brand)}
    />
  );
}
export function BrandList() {
  const { brandList } = useBrandList();
  return (
    <div>
      <h1>Brands</h1>
      <DataGrid
        style={{ minWidth: "550px", minHeight: "300px" }}
        items={brandList}
        columns={brandColumns}
      >
        <DataGridHeader>
          <DataGridRow>
            {({ renderHeaderCell }) => (
              <DataGridHeaderCell>{renderHeaderCell()}</DataGridHeaderCell>
            )}
          </DataGridRow>
        </DataGridHeader>
        <DataGridBody<Item>>
          {({ item, rowId }) => (
            <DataGridRow<Item> key={rowId}>
              {({ renderCell }) => (
                <DataGridCell>{renderCell(item)}</DataGridCell>
              )}
            </DataGridRow>
          )}
        </DataGridBody>
      </DataGrid>
      <AddBrand />
    </div>
  );
}
