import styled from "styled-components";
import { useCarForCardQuery } from "../generated/graphql";
import { RoundedButton } from "./roundedButton";
import { TractionValue } from "./tractionValue";

const Container = styled.div`
  width: 360px;
  height: 500px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  text-align: center;

  color: rgb(102, 102, 102);
  display: flex;
  flex-direction: column;
  padding: 1em;
  box-sizing: border-box;
  background-color: white;
  align-items: center;
  h1 {
    margin: 0.3em;
  }
  p {
    margin: 0;
  }
  img {
    height: 200px;
    width: 300px;
    object-fit: contain;
    object-position: center;
    margin-top: 1em;
  }
  @media screen and (max-width: 550px) {
    width: 280px;
    padding: 0;
    padding-top: 1em;
    img {
      height: 200px;
      width: 250px;
      object-fit: contain;
      object-position: center;
      margin-top: 1em;
    }
  }
`;
const PriceBlock = styled.div`
  color: #be1522;
  padding-top: 1em;
  font-size: 1.5em;
  font-weight: bold;
  padding-bottom: 0.5em;
  h1 {
    display: inline;
    margin: 0;
  }
`;

const TractionBlock = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  gap: 0.5em;
  justify-content: center;
  line-height: 1;
  margin-bottom: 1em;
  img {
    width: 30px;
    height: auto;
    max-height: 30px;
    object-fit: contain;
  }
`;

const TractionIcon = (props: {
  type: "ibrid" | "termic" | "electric";
  traction: string;
}) => {
  return (
    <TractionBlock>
      {props.type === "ibrid" && <img src="/iconsTractions/ibrida.webp"></img>}
      {props.type === "electric" && (
        <img src="/iconsTractions/elettrica.webp"></img>
      )}
      {props.type === "termic" && (
        <img src="/iconsTractions/termica.webp"></img>
      )}
      <TractionValue val={props.traction} />
    </TractionBlock>
  );
};

export function CarCard(props: { id?: string }) {
  const [{ data }] = useCarForCardQuery({ variables: { id: props.id } });
  const model = data?.models_by_pk;
  const price = model?.prices.at(0);
  return (
    <Container>
      <h1>{model?.brand}</h1>
      <p>{model?.model_name}</p>

      <img
        src={
          "https://automotive-services.netlify.app/.netlify/images?url=" +
          data?.models_by_pk?.imgs?.at(0) +
          "&h=300"
        }
      ></img>
      <PriceBlock>
        <h1>{price?.monthly_payment}</h1>€ al mese i.e.
      </PriceBlock>
      <div>{`${
        price?.rental_duration
      } mesi | ${price?.rental_distance.toLocaleString(
        "en-US"
      )} km | Anticipo ${price?.down_payment}€`}</div>
      {model?.traction_type === "ibrida" && (
        <TractionIcon type="ibrid" traction={model?.traction_type} />
      )}
      {model?.traction_type === "elettrica" && (
        <TractionIcon type="electric" traction={model?.traction_type} />
      )}

      {model?.traction_type === "diesel" && (
        <TractionIcon type="termic" traction={model?.traction_type} />
      )}

      {model?.traction_type === "benzina" && (
        <TractionIcon type="termic" traction={model?.traction_type} />
      )}

      {model?.traction_type === "benzina_full_hybrid" && (
        <TractionIcon type="ibrid" traction={model?.traction_type} />
      )}

      {model?.traction_type === "benzina_mild_hybrid" && (
        <TractionIcon type="ibrid" traction={model?.traction_type} />
      )}
      {model?.traction_type === "diesel_mild_hybrid" && (
        <TractionIcon type="ibrid" traction={model?.traction_type} />
      )}
      {model?.traction_type === "benzina_plug_in" && (
        <TractionIcon type="ibrid" traction={model?.traction_type} />
      )}
      {model?.traction_type === "diesel_plug_in" && (
        <TractionIcon type="ibrid" traction={model?.traction_type} />
      )}

      <a href={"/carprice/" + model?.id}>
        <RoundedButton className="solid">PERSONALIZZA L' OFFERTA</RoundedButton>
      </a>
    </Container>
  );
}
