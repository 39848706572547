import { createContext, useContext, useEffect, useState } from "react";
import {
  ModelQuery,
  useModelQuery,
  ModelsQuery,
  useModelsQuery,
  useUpdateModelMutation,
  DeleteModelMutation,
  useDeleteModelMutation,
} from "../../../generated/graphql";
import { FormInputs } from "./common";

type AutoEditorContextType = {
  selectedModel?: string;
  models: ModelsQuery["models"];
  setSelectedModel: React.Dispatch<React.SetStateAction<string | undefined>>;
  modelDetail?: ModelQuery["models_by_pk"];
};

const AutoEditorContext = createContext<AutoEditorContextType>({
  models: [],
  setSelectedModel: () => 0,
});

export const AutoEditorProvider = (props: { children: React.ReactNode }) => {
  const [{ data, fetching, error }] = useModelsQuery();
  const [selectedModel, setSelectedModel] = useState<string | undefined>(
    undefined
  );
  const [modelData, refreshModel] = useModelQuery({
    variables: { id: selectedModel },
  });

  return (
    <AutoEditorContext.Provider
      value={{
        models: data?.models || [],
        selectedModel,
        setSelectedModel,
        modelDetail: modelData.data?.models_by_pk,
      }}
    >
      {props.children}
    </AutoEditorContext.Provider>
  );
};

export function useAutoEditor() {
  const context = useContext(AutoEditorContext);

  if (!context) {
    throw new Error("useAutoEditor must be used within a AutoEditorProvider");
  }

  const [_res, updateModelMut] = useUpdateModelMutation();
  const [_resD, DeleteModelMutation] = useDeleteModelMutation();

  async function deleteModel() {
    if (context.selectedModel === undefined) return;

    const result = await DeleteModelMutation({ id: context.selectedModel });
    context.setSelectedModel(undefined);
    console.log(result);
  }
  async function updateModel(form: FormInputs) {
    console.log(form);
    const result = await updateModelMut({
      id: context.selectedModel,
      brand: form.brand,
      engine: form.engine,
      model_name: form.model_name,
      traction_type: form.traction_type,
      furgone: form.furgone,
      imgs: form.imgs,
      prices: form.prices,
    });
    console.log("result", result);
  }
  return { ...context, updateModel, deleteModel };
}
export type { AutoEditorContextType };
