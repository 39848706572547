import styled from "styled-components";
import { DescriptiveBlockWrapped } from "../../components/descriptiveBlock";
import { NewsLetter } from "../../components/newsLetter";

import { MonthlyOffers } from "../home/monthlyOffers";

import { Accordion } from "../../components/accordionInfo";
import { ChiSiamoDesc } from "./chiSiamoDesc";
import { Videos } from "../whatIsRent/videos";
import { RoundedInfo } from "../../components/roundedInfo";

const ImgBanner = styled.img`
  width: 100%;
  height: auto;
`;

export function ChiSiamo() {
  return (
    <>
      <ImgBanner src="/img/chisiamo.webp"></ImgBanner>
      <ChiSiamoDesc />

      <RoundedInfo inverted reverse id="rDWZpbCcVCiV6vR8swUfk"></RoundedInfo>
      <RoundedInfo inverted id="6YB8KqyNHAAv6w3yYIU9Ap"></RoundedInfo>

      <RoundedInfo reverse inverted id="4iPQnF7nGZrNXGMBkZMUno"></RoundedInfo>

      <RoundedInfo id="6UWfZxMMgpLX9mbeCdohIZ"></RoundedInfo>
      <MonthlyOffers id="7nZE1vjk1hSdsGyWpb7c8G" size={3} />
      <Videos />

      <NewsLetter />
    </>
  );
}
