import { Label, Input, Select, Checkbox } from "@fluentui/react-components";
import { AutoEditorContextType } from "./autoEditorProvider";
import { useForm, Controller, Control } from "react-hook-form";
import {
  InputMaybe,
  Tractions_Enum,
  UpdateModelMutation,
  UpdateModelMutationVariables,
} from "../../../generated/graphql";

export type FormInputs = {
  modelDetail: string;
  brand: string;
  engine: string;
  model_name: string;
  furgone: boolean;
  traction_type: InputMaybe<Tractions_Enum>;
  prices: UpdateModelMutationVariables["prices"];
  imgs: string[];
};
export function TextInput(props: {
  displayName: string;
  value?: string;
  name: keyof NonNullable<AutoEditorContextType["modelDetail"]>;
  control: Control<FormInputs, any>;
}) {
  if (props.value === undefined) return null;
  return (
    <Controller
      control={props.control}
      //@ts-ignore
      name={props.name}
      defaultValue={props.value}
      render={({ field: { onChange, value } }) => (
        <>
          <Label htmlFor={props.displayName}>{props.displayName}: </Label>
          <Input
            name={props.name}
            value={value as string}
            id={props.displayName}
            onChange={(event, data) => onChange(data.value)}
          />
        </>
      )}
    />
  );
}
export function SelectInput(props: {
  displayName: string;
  options?: React.ReactNode;
  value?: string;
  name: keyof NonNullable<AutoEditorContextType["modelDetail"]>;
  control: Control<FormInputs, any>;
}) {
  if (!props.value) return null;
  return (
    <Controller
      control={props.control}
      //@ts-ignore
      name={props.name}
      defaultValue={props.value}
      render={({ field: { onChange, value } }) => (
        <>
          <Label htmlFor={props.displayName}>{props.displayName}: </Label>
          <Select
            value={value as string}
            id={props.displayName}
            onChange={(event, data) => onChange(data.value)}
          >
            {props.options}
          </Select>
        </>
      )}
    />
  );
}

export function CheckBox(props: {
  displayName: string;
  options?: React.ReactNode;
  value?: boolean;
  name: keyof NonNullable<AutoEditorContextType["modelDetail"]>;
  control: Control<FormInputs, any>;
}) {
  if (props.value === undefined) return null;
  return (
    <Controller
      control={props.control}
      //@ts-ignore
      name={props.name}
      defaultValue={props.value}
      render={({ field: { onChange, value } }) => (
        <>
          <Label htmlFor={props.displayName}>{props.displayName}: </Label>
          <Checkbox
            checked={value as boolean}
            id={props.displayName}
            onChange={(event, data) => {
              onChange(data.checked);
            }}
          ></Checkbox>
        </>
      )}
    />
  );
}
