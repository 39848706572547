import { styled } from "styled-components";
import { useParams } from "react-router";
import { useModelInfoQuery } from "../../generated/graphql";
import { TractionValue } from "../../components/tractionValue";
const Container = styled.div`
  width: 100%;
  max-width: var(--page-max-width);
  text-transform: uppercase;
  border-top: 1px solid black;
  border-bottom: 1px solid black;
  padding-bottom: 1em;
  box-sizing: border-box;
  margin-top: 2em;

  @media screen and (max-width: 500px) {
    font-size: 0.8em;
  }
  h1 {
    line-height: 1em;
  }
`;
const SpecGrid = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  grid-template-rows: 20px 20px;
`;
const HeaderRow = styled.div`
  display: contents;
  font-weight: bold;
`;
const ContentRow = styled.div`
  display: contents;
  & div {
    background-color: #d9d9d97d;
  }
`;

export function CarInfo() {
  let { id } = useParams();
  const [{ data }] = useModelInfoQuery({
    variables: {
      id: id,
    },
  });
  const modelData = data?.models_by_pk;
  return (
    <Container>
      <h1>
        Specifiche {modelData?.brand} {modelData?.model_name}
      </h1>
      <SpecGrid>
        <HeaderRow>
          <div>modello</div>

          <div>alimentazione</div>
        </HeaderRow>
        <ContentRow>
          <div>{modelData?.model_name}</div>
          <div>
            {" "}
            <TractionValue val={modelData?.traction_type || ""} />
          </div>
        </ContentRow>
      </SpecGrid>
    </Container>
  );
}
