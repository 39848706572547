import styled from "styled-components";
import { WrapContainer } from "./wrapContainer";
import React from "react";
import { useDescriptiveBlockQuery } from "../generated/graphql";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";

export function DescriptiveBlock(props: {
  id: string;
  children?: React.ReactNode;
}) {
  const [{ data }] = useDescriptiveBlockQuery({ variables: { id: props.id } });
  return (
    <>
      <h1>{data?.descriptiveBlock?.title}</h1>
      {documentToReactComponents(data?.descriptiveBlock?.description?.json)}
      {props.children}
    </>
  );
}
const Container = styled.div<{ center?: boolean }>`
  max-width: var(--page-max-width);
  width: 100%;
  padding-top: 2em;
  padding-bottom: 2em;
  text-align: ${(props) => props.center && "center"};
  p {
    max-width: var(--page-max-width);
    white-space: break-words;
  }
  h1 {
    line-height: 1;
  }
`;

export function DescriptiveBlockWrapped(props: {
  id: string;
  children?: React.ReactNode;
  red?: boolean;
  grey?: boolean;
  center?: boolean;
  black?: boolean;
}) {
  return (
    <WrapContainer grey={props.grey} red={props.red} black={props.black}>
      <Container center={props.center}>
        <DescriptiveBlock {...props} />
      </Container>
    </WrapContainer>
  );
}
