import { Button, Input } from "@fluentui/react-components";
import { useState } from "react";
import { useBrandList } from "./brandListProvider";
export function AddBrand() {
  const [value, setValue] = useState("");
  const { addBrand } = useBrandList();
  return (
    <>
      <Input
        value={value}
        onChange={(_e, data) => setValue(data.value)}
        appearance="underline"
        placeholder="Nuovo brand"
        style={{ marginRight: 10 }}
      />
      <Button onClick={() => addBrand(value)}>Aggiungi</Button>
    </>
  );
}
