import styled from "styled-components";
import Slider from "@mui/material/Slider";
import React, { useEffect } from "react";
import {
  useCarForCardQuery,
  useFinalPricesQuery,
} from "../../generated/graphql";
import { useParams } from "react-router";
import { distanceMarks, durationsMarks } from "./marks";
import { RoundedButton } from "../../components/roundedButton";
import { TractionValue } from "../../components/tractionValue";
import { set } from "react-hook-form";

const Container = styled.div`
  background-color: #f6f6f6;
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-template-rows: auto auto 100px 100px 80px;
  grid-row-gap: 2em;
  height: fit-content;
  max-width: 480px;

  width: 100%;

  justify-self: end;
  @media screen and (max-width: 950px) {
    margin: 0;
    justify-self: center;
    grid-template-rows: auto auto 100px 100px 80px;
  }
`;
const SliderCustom = styled(Slider)`
  width: calc(100% - 20px) !important;

  color: #be1522 !important;
  .MuiSlider-thumb {
    height: 20px !important;
    width: 20px !important;
    border-radius: 1px !important;
  }
  .MuiSlider-track {
    border-radius: 0px !important;
  }
  .MuiSlider-rail {
    background-color: #d9d9d9 !important;
    border: 1px solid #d9d9d9 !important;
    border-radius: 0px !important;
  }
  .MuiSlider-mark {
    background-color: transparent !important;
  }
`;
const SliderTile = styled.div`
  padding: 1em;
  grid-column: span 3;
  p {
    border-bottom: 1px solid #868585;
    padding-bottom: 1em;
    width: 100%;
  }
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  grid-column: span 3;
  @media screen and (max-width: 950px) {
    padding: 1em;
    p {
      text-align: center;
    }
  }
`;
const CheckBoxContainer = styled.label`
  padding: 0.5em 0.5em;
  grid-column: span 2;
  p {
    text-align: center;
    border-bottom: 1px solid #868585;
    padding-bottom: 1em;
    font-size: 12px;
    width: 100%;
    @media screen and (max-width: 950px) {
      font-size: 10px;
    }
  }
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  & input {
    height: 20px;
    width: 20px;
    border-radius: 5px;
  }
  input[type="checkbox"] {
    /* removing default appearance */
    -webkit-appearance: none;
    appearance: none;
    /* creating a custom design */
    width: 1.6em;
    height: 1.6em;
    border-radius: 50%;
    margin-right: 0.5em;
    border: 0.15em solid #be1522;
    outline: none;
    cursor: pointer;
  }
  input[type="checkbox"]:checked {
    border: 0.15em solid #868585;
    background-color: #be1522;
  }
`;
const CarDetailBlock = styled.div`
  padding: 2em;
  grid-column: span 3;
  h2 {
    font-weight: 400;
    font-size: 30px;
    line-height: 1em;
    margin: 0;
    padding: 0;
    padding-bottom: 0.5em;
  }
  p {
    margin: 0;
    padding: 0;
  }
  @media screen and (max-width: 650px) {
    justify-self: center;

    padding: 2em 0em;
  }
`;

const PriceBlock = styled.div`
  padding: 2em;
  grid-column: span 3;
  h2 {
    display: inline;
    font-variant-numeric: tabular-nums;
  }
  h1 {
    font-weight: 400;
    font-size: 45px;
    margin: 0;
    padding: 0;
    padding-bottom: 1em;
    min-width: 10ch;
    line-height: 1em;
  }
  h2 {
    font-weight: 800;
    font-size: 45px;
    margin: 0;
    padding: 0;
    color: #be1522;
  }
  p {
    font-size: 20px;
    margin: 0;
    padding: 0;
  }
  h3 {
    font-size: 16px;
  }
  @media screen and (max-width: 650px) {
    h1 {
      font-size: 30px;
      min-width: 8ch;
    }
    h2 {
      font-size: 30px;
    }
    justify-self: center;

    padding: 2em 0em;
  }
`;
const LinkPrice = styled.a`
  grid-column: span 6;
  justify-self: center;
  margin-bottom: 2em;
`;

const TextAnticipo = styled.p`
  grid-column: span 6;
  padding: 0 1em;
  margin: 0;
  font-size: 0%.7;
  span {
    color: #be1522;
  }
`;

function useFavorite(id: string) {
  const [{ data }] = useCarForCardQuery({ variables: { id: id } });

  return {
    distance: data?.models_by_pk?.prices[0].rental_distance,
    duration: data?.models_by_pk?.prices[0].rental_duration,
  };
}
export function PriceSelector() {
  let { id } = useParams();

  const { distance, duration } = useFavorite(id || "");
  const [noVat, setNoVat] = React.useState(false);

  const [value, setValue] = React.useState({
    distance: 10000,
    duration: 24,
    tyres: false,
    courtesy_car: false,
  });
  useEffect(() => {
    setValue({
      distance: distance || 10000,
      duration: duration || 24,
      tyres: false,
      courtesy_car: false,
    });
  }, [distance, duration]);

  const [{ data }] = useFinalPricesQuery({
    variables: {
      distance: value.distance,
      duration: value.duration,
      courtesy_car: value.courtesy_car,
      tyres: value.tyres,
      id: id,
    },
  });
  return (
    <Container>
      <PriceBlock>
        <h1>{data?.models_by_pk?.brand}</h1>
        <p>
          <h2>
            {Math.round(
              data?.final_prices[0].final_monthly_price * (noVat ? 1.22 : 1)
            )}
            €
          </h2>
          /mese
        </p>
      </PriceBlock>

      <CarDetailBlock>
        <h2>{data?.models_by_pk?.model_name}</h2>
        <p>
          {data?.models_by_pk?.engine} <br />
          <TractionValue val={data?.models_by_pk?.traction_type || ""} />
        </p>
        <h3>
          Anticipo{" "}
          {Math.round(data?.final_prices[0].down_payment * (noVat ? 1.22 : 1))}€
        </h3>
      </CarDetailBlock>
      <TextAnticipo>
        <b>
          Veicolo disponibile anche <span>anticipo 0</span>, contattaci per un
          preventivo personalizzato
        </b>
      </TextAnticipo>
      <SliderTile>
        <p>Chilometri/anno</p>
        <SliderCustom
          aria-label="Restricted values"
          value={value.distance}
          onChange={(event, newValue) =>
            setValue({ ...value, distance: newValue as number })
          }
          step={null}
          valueLabelDisplay="off"
          marks={distanceMarks}
          max={30000}
          min={10000}
        />
      </SliderTile>

      <SliderTile>
        <p>Durata mesi</p>
        <SliderCustom
          aria-label="Restricted values"
          value={value.duration}
          onChange={(event, newValue) =>
            setValue({ ...value, duration: newValue as number })
          }
          step={null}
          valueLabelDisplay="off"
          marks={durationsMarks}
          min={24}
          max={60}
        />
      </SliderTile>

      <CheckBoxContainer htmlFor="auto">
        <p>Auto Sostitutiva</p>
        <input
          type="checkbox"
          id="auto"
          checked={value.courtesy_car}
          onChange={(event) =>
            setValue({ ...value, courtesy_car: !value.courtesy_car })
          }
        />
      </CheckBoxContainer>
      <CheckBoxContainer htmlFor="tyres">
        <p>Cambio gomme</p>

        <input
          type="checkbox"
          id="tyres"
          checked={value.tyres}
          onChange={(event) => setValue({ ...value, tyres: !value.tyres })}
        />
      </CheckBoxContainer>

      <CheckBoxContainer htmlFor="iva">
        <p>Iva inclusa</p>

        <input
          type="checkbox"
          id="iva"
          checked={noVat}
          onChange={(event) => setNoVat(!noVat)}
        />
      </CheckBoxContainer>
      <LinkPrice href="/preventivo">
        <RoundedButton className="solid">Richiedi un preventivo</RoundedButton>
      </LinkPrice>
    </Container>
  );
}
