import { Button, Spinner } from "@fluentui/react-components";
import { HasuraStorageClient } from "@nhost/hasura-storage-js";
import { useEffect, useState } from "react";
import { styled } from "styled-components";
import { DeleteRegular } from "@fluentui/react-icons";
import {
  DndContext,
  DragEndEvent,
  useDndContext,
  useDraggable,
  useDroppable,
} from "@dnd-kit/core";
function moveElement<T>(arr: T[], fromIndex: number, toIndex: number): T[] {
  // Create a copy of the array to avoid modifying the original
  const result = [...arr];

  // Check if indices are valid
  if (
    fromIndex < 0 ||
    fromIndex >= arr.length ||
    toIndex < 0 ||
    toIndex >= arr.length
  ) {
    throw new Error("Invalid indices");
  }

  // Remove the element from the original position
  const [element] = result.splice(fromIndex, 1);

  // Insert the element at the target position
  result.splice(toIndex, 0, element);

  return result;
}
function switchElements<T>(arr: T[], index1: number, index2: number): T[] {
  // Create a copy of the original array
  const newArr = [...arr];

  // Check if indices are valid
  if (
    index1 < 0 ||
    index1 >= arr.length ||
    index2 < 0 ||
    index2 >= arr.length
  ) {
    throw new Error("Invalid indices");
  }

  // Perform the swap
  [newArr[index1], newArr[index2]] = [newArr[index2], newArr[index1]];

  return newArr;
}
const password = localStorage.getItem("password");
const storage = new HasuraStorageClient({
  adminSecret: password || "",

  url: "https://gnehjbtszplnnrklqbwb.storage.eu-central-1.nhost.run/v1",
});

const UpButton = styled.label`
  border: 1px solid black;

  padding: 0.5em;
  cursor: pointer;
`;
const PicContainers = styled.div`
  padding: 1em;
  display: flex;

  img {
    height: 150px;
    width: 150px;
    object-fit: contain;
  }
`;
const Pic = styled.div`
  flex-direction: column;
  align-items: center;
  display: flex;

  .hid {
    visibility: hidden;
  }
  &:hover {
    .hid {
      visibility: visible;
    }
  }
`;
export function PicUpload(props: {
  default: string[];
  onChange: (files: string[]) => void;
}) {
  const [files, setFiles] = useState<string[]>(props.default);
  const [loading, setLoading] = useState(false);
  function handleDragEnd(event: DragEndEvent) {
    if (event.over) {
      console.log(event);
      const active = (event.active.id as number) - 1;
      let over = event.over.id as number;
      if (active < over) {
        over--;
      }
      setFiles((prev) => moveElement(prev, active, over));
    }
  }
  async function handleChange(event: React.ChangeEvent<HTMLInputElement>) {
    event.preventDefault();

    for (const file of Array.from(event.target.files || [])) {
      setLoading(true);
      const result = await storage.upload({ file });
      setLoading(false);
      const id = result.fileMetadata?.id;
      if (id) {
        const url = storage.getPublicUrl({ fileId: id });
        setFiles((prev) => [...prev, url || ""]);
      }
    }
  }
  useEffect(() => {
    props.onChange(files);
  }, [files, props]);

  useEffect(() => {
    setFiles(props.default);
  }, [props.default]);

  function DeletePic(url: string) {
    console.log("delte");
    setFiles((prev) => prev.filter((u) => u !== url));
  }
  return (
    <div>
      <PicContainers>
        <DndContext onDragEnd={handleDragEnd}>
          {files.map((url, index) => (
            <>
              <Droppable id={index} key={url + "s"}></Droppable>

              <Pic>
                <Draggable id={index + 1} key={url}>
                  <img src={url + "?h=300"} alt="uploaded" />
                </Draggable>
                <Button
                  onClick={() => DeletePic(url)}
                  appearance="transparent"
                  className="hid"
                  size="large"
                  icon={<DeleteRegular />}
                ></Button>
              </Pic>
            </>
          ))}
          <Droppable id={files.length} key={"end"}></Droppable>

          {loading && <Spinner />}
        </DndContext>
      </PicContainers>
      <UpButton htmlFor="file">Carica foto</UpButton>
      <input
        type="file"
        id="file"
        name="file"
        multiple
        style={{ visibility: "hidden" }}
        onChange={handleChange}
      />
    </div>
  );
}

function Draggable(props: { children: React.ReactNode; id: number }) {
  const { attributes, listeners, setNodeRef, transform } = useDraggable({
    id: props.id,
  });
  const style = transform
    ? {
        transform: `translate3d(${transform.x}px, ${transform.y}px, 0)`,
        cursor: "grab",
        backgroundColor: "white",

        borderRadius: "5px",
        border: "1px solid black",
      }
    : { cursor: "grab", backgroundColor: "transparent", border: "none" };

  return (
    <button ref={setNodeRef} style={style} {...listeners} {...attributes}>
      {props.children}
    </button>
  );
}

const DropContainer = styled.div`
  height: 200px;
  width: 20px;
`;
function Droppable(props: { id: number }) {
  const { active } = useDndContext();

  const isActiveDrag = active !== null;

  const { isOver, setNodeRef } = useDroppable({
    id: props.id,
  });
  const styleDrag = {
    backgroundColor: "lightblue",
    borderRadius: "5px",
  };

  const styleOver = {
    backgroundColor: "blue",
    borderRadius: "5px",
  };
  const style = isOver ? styleOver : isActiveDrag ? styleDrag : {};
  return <DropContainer ref={setNodeRef} style={style}></DropContainer>;
}
