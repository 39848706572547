import styled from "styled-components";
import { WrapContainer } from "../../components/wrapContainer";

const Container = styled.div`
  width: 100%;

  max-width: var(--page-max-width);
  padding: 3em 0px;
  border-top: 1px solid black;
  color: white;

  text-align: center;
  text-transform: uppercase;
  h1 {
    line-height: 1.3em;
  }
`;
const FlexContainer = styled.div`
  display: flex;

  flex-direction: row;
  gap: 2em;
  @media screen and (max-width: 1100px) {
    flex-direction: column;
    gap: 3em;
    align-items: center;
    iframe {
      height: 300px;
      max-width: 500px;

      aspect-ratio: 16/9;
    }
  }
`;
export const YoutubeEmbed = (props: { embedId: string }) => (
  <iframe
    width="100%"
    src={`https://www.youtube.com/embed/${props.embedId}`}
    frameBorder="0"
    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
    allowFullScreen
    title="Embedded youtube"
    style={{ aspectRatio: "16/9" }}
  />
);

export function Videos() {
  return (
    <WrapContainer black>
      <Container>
        <h2>SCOPRI DI PIù SUL NOLEGGIO</h2>
        <FlexContainer>
          <YoutubeEmbed embedId="FvdspUC1w60"></YoutubeEmbed>
          <YoutubeEmbed embedId="7wb9s__s5lU"></YoutubeEmbed>
          <YoutubeEmbed embedId="cSiCAIsrzzY"></YoutubeEmbed>
        </FlexContainer>
      </Container>
    </WrapContainer>
  );
}
