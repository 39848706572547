import styled from "styled-components";
import { WrapContainer } from "../../components/wrapContainer";

const Container = styled.div`
  width: 100%;

  max-width: var(--page-max-width);
  border-top: 1px solid black;
  h1 {
    line-height: 1.3em;
  }
`;
const Row = styled.div`
  display: contents;
  div {
    background-color: inherit;
    padding: 0.5em;
  }
`;
const TableContainer = styled.div`
  display: grid;
  grid-template-columns: auto minmax(70px, 1fr) minmax(70px, 1fr) minmax(
      70px,
      1fr
    );

  text-align: center;
  ${Row}:nth-child(odd) {
    background-color: #d9d9d9;
  }
  ${Row}:nth-child(even) {
    background-color: #d9d9d97f;
  }
`;
const Header = styled.div`
  display: contents;

  div {
    font-weight: bold;
    align-self: center;
  }
`;

const LeftTitle = styled.div`
  text-align: left;
`;
const Small = styled.span`
  font-size: 0.7em;
`;
const Icon = styled.img`
  height: 20px;
  width: 100%;
  object-fit: contain;
`;

export function RentTable() {
  return (
    <WrapContainer>
      <Container>
        <h1>PERCHÈ SCEGLIERE IL NOLEGGIO?</h1>
        <TableContainer>
          <div></div>
          <Header>
            <div>Noleggio a lungo termine</div>
            <div>Leasing</div>
            <div>Acquisto</div>
          </Header>
          <Row>
            <LeftTitle>
              <b>ASSICURAZIONE </b>
              <Small>(RCA, KASKO, furto e incendio, conducente)</Small>
            </LeftTitle>
            <div>
              <Icon src="/infoIcons/yes.webp" />
            </div>

            <div>
              <Icon src="/infoIcons/no.webp" />
            </div>
            <div>
              <Icon src="/infoIcons/no.webp" />
            </div>
          </Row>
          <Row>
            <LeftTitle>
              <b>MANUTENZIONE COMPLETA</b>
            </LeftTitle>
            <div>
              <Icon src="/infoIcons/yes.webp" />
            </div>

            <div>
              <Icon src="/infoIcons/no.webp" />
            </div>
            <div>
              <Icon src="/infoIcons/no.webp" />
            </div>
          </Row>
          <Row>
            <LeftTitle>
              <b>CANONE FISSO</b>
            </LeftTitle>
            <div>
              <Icon src="/infoIcons/yes.webp" />
            </div>

            <div>
              <Icon src="/infoIcons/no.webp" />
            </div>
            <div>
              <Icon src="/infoIcons/no.webp" />
            </div>
          </Row>
          <Row>
            <LeftTitle>
              <b>SOCCORSO STRADALE</b>
            </LeftTitle>
            <div>
              <Icon src="/infoIcons/yes.webp" />
            </div>

            <div>
              <Icon src="/infoIcons/no.webp" />
            </div>
            <div>
              <Icon src="/infoIcons/no.webp" />
            </div>
          </Row>
          <Row>
            <LeftTitle>
              <b>DURATA</b>
            </LeftTitle>
            <div>
              <Small>Da 24 a 60 mesi</Small>
            </div>
            <div>
              <Small>Da 24 a 60 mesi</Small>
            </div>
            <div>
              <Small>Nessun limite</Small>
            </div>
          </Row>
        </TableContainer>
      </Container>
    </WrapContainer>
  );
}
