import styled from "styled-components";
import {
  useDescriptiveBlockQuery,
  useAddSubNewsletterMutation,
} from "../generated/graphql";
import { WrapContainer } from "./wrapContainer";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { Input } from "./Input";
import { RoundedButton } from "./roundedButton";
import { useForm, SubmitHandler } from "react-hook-form";
import { useState } from "react";

const Container = styled.form`
  max-width: var(--page-max-width);
  width: 100%;
  padding-top: 2em;
  padding-bottom: 2em;
`;
const CenterButton = styled.div`
  display: flex;
  justify-content: center;
`;
const InputContainer = styled.div`
  display: flex;
  gap: 1em;
  width: 100%;
  input {
    width: 100%;
  }
  @media screen and (max-width: 700px) {
    flex-direction: column;
    input {
      width: auto;
    }
  }
`;

type Inputs = {
  name: string;
  lastname: string;
  email: string;
};

export function NewsLetter() {
  const [{ data }] = useDescriptiveBlockQuery({
    variables: { id: "ROmLE9ft1OAr0kq2XAuSZ" },
  });
  const [subscribed, setSubscribed] = useState(false);
  const [_, addSubNewsletter] = useAddSubNewsletterMutation();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<Inputs>();
  const onSubmit: SubmitHandler<Inputs> = (data) => {
    console.log(data);
    addSubNewsletter({ ...data }).then(() => setSubscribed(true));
  };

  return (
    <WrapContainer>
      <Container onSubmit={handleSubmit(onSubmit)}>
        <h2>{data?.descriptiveBlock?.title}</h2>
        {documentToReactComponents(data?.descriptiveBlock?.description?.json)}

        <InputContainer>
          <Input
            type="text"
            placeholder="Nome"
            required
            {...register("name")}
          />
          <Input
            type="text"
            placeholder="Cognome"
            required
            {...register("lastname")}
          />
          <Input
            type="email"
            placeholder="Email"
            required
            {...register("email")}
          />
        </InputContainer>
        <p>
          Iscrivendomi accetto di ricevere comunicazioni commerciali tramite
          e-mail. Per maggiori informazioni consulta la nostra informativa sulla
          privacy.
        </p>
        <CenterButton>
          {subscribed ? (
            <b>Grazie per esserti iscritto</b>
          ) : (
            <RoundedButton className="solid" type="submit">
              ISCRIVITI
            </RoundedButton>
          )}
        </CenterButton>
      </Container>
    </WrapContainer>
  );
}
