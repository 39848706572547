import styled, { css } from "styled-components";
import { WrapContainer } from "../../components/wrapContainer";
import { useState } from "react";
import { useDescriptiveBlockQuery } from "../../generated/graphql";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";

const Container = styled.div`
  width: 100%;

  max-width: var(--page-max-width);
  border-top: 1px solid black;
  h1 {
    line-height: 1.3em;
  }
`;

const TabBar = styled.div`
  width: 100%;
  height: 40px;
  border-bottom: 1px solid black;
  display: flex;
  flex-direction: row;
  @media screen and (max-width: 750px) {
    flex-direction: column;
    gap: 1em;
    height: auto;
    border-bottom: none;
  }
`;
const TabButton = styled.div<{ active?: boolean }>`
  height: calc(100% - 2px);
  padding: 0 1em;
  display: flex;
  align-items: baseline;
  img {
    height: 30px;
    margin-right: 0.5em;
  }
  ${(props) =>
    !props.active &&
    css`
      &:hover {
        border-bottom: 3px solid #c67f85;
        cursor: pointer;
      }
    `}

  ${(props) =>
    props.active &&
    css`
      border-bottom: 3px solid #be1522;
      font-weight: bold;
    `}
`;

function Advatange(props: { id: string }) {
  const [{ data }] = useDescriptiveBlockQuery({
    variables: { id: props.id },
  });

  return (
    <>{documentToReactComponents(data?.descriptiveBlock?.description?.json)}</>
  );
}
export function Advantages() {
  const [selectedTab, setSelectedTab] = useState(0);

  return (
    <WrapContainer>
      <Container>
        <h1>I VANTAGGI DEL NOLEGGIO</h1>
        <TabBar>
          <TabButton
            active={selectedTab === 0}
            onClick={() => setSelectedTab(0)}
          >
            <img src="/infoicons/saveMoney.webp" />
            RISPARMIO ECONOMICO
          </TabButton>
          <TabButton
            active={selectedTab === 1}
            onClick={() => setSelectedTab(1)}
          >
            <img src="/infoicons/document.webp" />
            SUPPORTO OPERATIVO
          </TabButton>
          <TabButton
            active={selectedTab === 2}
            onClick={() => setSelectedTab(2)}
          >
            <img src="/infoicons/newCar.webp" />
            ZERO PENSIERI
          </TabButton>
        </TabBar>
        {selectedTab === 0 && <Advatange id="24dRJ2hM1W7p0mqPPPN0zs" />}
        {selectedTab === 1 && <Advatange id="5XHYvgzBtNFdlUkbUJx89z" />}
        {selectedTab === 2 && <Advatange id="4sHDcIlwt2ei6iVAvAKiSC" />}
      </Container>
    </WrapContainer>
  );
}
