import { useParams } from "react-router";
import styled from "styled-components";
import { useModelInfoQuery } from "../../generated/graphql";
import { useState } from "react";
const Container = styled.div`
  display: grid;
  grid-template-rows: auto 1fr auto;
  grid-template-columns: 100%;

  width: 100%;
  max-width: 800px;
  p {
    padding: 1em;
  }
  & > img {
    max-width: 600px;
    width: 100%;
    object-fit: contain;
    object-position: center;
    align-self: center;
    justify-self: center;
    height: 400px;
  }
`;
const ImgThumbsContainer = styled.div`
  display: grid;
  grid-auto-rows: 80px;
  padding: 1em;

  grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
  grid-row-gap: 1em;
  grid-column-gap: 1em;

  @media screen and (max-width: 950px) {
    grid-auto-rows: 80px;
  }
`;
const ImgThumb = styled.img<{ $current?: boolean }>`
  width: 100%;
  height: 100%;
  object-fit: contain;
  opacity: ${(props) => (props.$current ? 1 : 0.5)};
  cursor: ${(props) => (props.$current ? "auto" : "pointer")};
  transition: opacity 0.2s;
`;

export function CarPics() {
  const [selectedImg, setSelectedImg] = useState(0);
  let { id } = useParams();
  const [{ data }] = useModelInfoQuery({
    variables: {
      id: id,
    },
  });

  return (
    <Container>
      <p>
        {data?.models_by_pk?.brand} {data?.models_by_pk?.model_name}
      </p>
      <img
        src={
          "https://automotive-services.netlify.app/.netlify/images?url=" +
          data?.models_by_pk?.imgs?.at(selectedImg) +
          "&h=800"
        }
      ></img>
      <ImgThumbsContainer>
        {data?.models_by_pk?.imgs?.map((val, index) => (
          <ImgThumb
            src={
              "https://automotive-services.netlify.app/.netlify/images?url=" +
              val +
              "?h=300"
            }
            onClick={() => setSelectedImg(index)}
            $current={index === selectedImg}
            key={"pic" + index}
          />
        ))}
      </ImgThumbsContainer>
    </Container>
  );
}
