import { DescriptiveBlockWrapped } from "../../components/descriptiveBlock";
import { NewsLetter } from "../../components/newsLetter";
import { RoundedButton } from "../../components/roundedButton";
import { HomeIcons } from "./homeIcons";
import { HomeSlider } from "./homeSlider";
import { MonthlyOffers } from "./monthlyOffers";

export function Home() {
  return (
    <div>
      <HomeSlider></HomeSlider>
      <DescriptiveBlockWrapped id="1TYRyRsEeYtYVOeo2W0TBQ" />
      <MonthlyOffers id="4ljacktE3qwRMd0vqpfx3W" size={6} />
      <HomeIcons />
      <DescriptiveBlockWrapped id="6Ld3lqiOdDsZkqxoeu5ZIi" red center>
        <a href="/contatti">
          <RoundedButton className="white">CONTATTACI</RoundedButton>
        </a>
      </DescriptiveBlockWrapped>
      <NewsLetter />
    </div>
  );
}
