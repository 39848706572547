import { createContext, useContext } from "react";
import {
  BrandsQuery,
  useBrandsQuery,
  useDeleteBrandMutation,
  useInsertBrandMutation,
} from "../../../generated/graphql";

const BrandContext = createContext({
  brandList: [] as BrandsQuery["brands"],
});

export const BrandProvider = (props: { children: React.ReactNode }) => {
  const [{ data, fetching, error }] = useBrandsQuery();

  return (
    <BrandContext.Provider value={{ brandList: data?.brands || [] }}>
      {props.children}
    </BrandContext.Provider>
  );
};

export function useBrandList() {
  const context = useContext(BrandContext);
  const [_, insertBrand] = useInsertBrandMutation();
  const [_d, deleteBrandMut] = useDeleteBrandMutation();
  if (!context) {
    throw new Error("useBrandList must be used within a BrandProvider");
  }
  function addBrand(name: string) {
    insertBrand({ name });
  }
  function deleteBrand(name: string) {
    deleteBrandMut({ name });
  }

  return { ...context, addBrand, deleteBrand };
}
