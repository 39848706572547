import { css } from "styled-components";
import styled from "styled-components";
import { useDescriptiveBlockQuery } from "../generated/graphql";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";

const Container = styled.div<{ reverse?: boolean; inverted?: boolean }>`
  display: flex;

  width: 100%;

  flex-direction: ${(props) => (props.reverse ? "row-reverse" : "row")};

  justify-content: space-between;
  ${(props) =>
    !props.inverted &&
    css`
      background-color: #be1522;
      color: white;
    `}
  p {
    max-width: 800px;
    white-space: break-spaces;
    padding: 2em;
    margin-left: ${(props) => !props.reverse && "auto"};
    margin-right: ${(props) => props.reverse && "auto"};
  }

  @media screen and (max-width: 500px) {
    flex-direction: column;
    p {
      padding: 1em;
      text-align: center;
    }
    padding-bottom: 1em;
  }
`;
const RoundedButton = styled.div<{ reverse?: boolean; inverted?: boolean }>`
  font-weight: bold;
  padding: 1.2em 2em;

  ${(props) =>
    props.inverted
      ? css`
          background-color: #be1522;
          color: white;
        `
      : css`
          background-color: white;
          color: black;
        `}

  ${(props) =>
    props.reverse
      ? css`
          border-bottom-right-radius: 10px;
          border-top-right-radius: 10px;
        `
      : css`
          border-bottom-left-radius: 10px;
          border-top-left-radius: 10px;
        `}


        @media screen and (max-width: 500px) {
    border-radius: 10px;
  }
`;
const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export function RoundedInfo(props: {
  reverse?: boolean;
  inverted?: boolean;
  id: string;
}) {
  const [{ data }] = useDescriptiveBlockQuery({ variables: { id: props.id } });

  return (
    <Container reverse={props.reverse} inverted={props.inverted}>
      {documentToReactComponents(data?.descriptiveBlock?.description?.json)}

      <ButtonContainer>
        <RoundedButton reverse={props.reverse} inverted={props.inverted}>
          {data?.descriptiveBlock?.title}
        </RoundedButton>
      </ButtonContainer>
    </Container>
  );
}
