import styled from "styled-components";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { useDescriptiveBlockQuery } from "../../generated/graphql";
import { faEnvelope, faPhone } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebook,
  faInstagram,
  faWhatsapp,
} from "@fortawesome/free-brands-svg-icons";
import { IconProp } from "@fortawesome/fontawesome-svg-core";

const Container = styled.div<{ center?: boolean }>`
  max-width: 400px;
  width: 100%;
  background-color: #000000ce;
  border-bottom-left-radius: 50px;
  border-top-left-radius: 50px;
  color: white;
  box-sizing: border-box;
  padding: 3em 3em;
  justify-content: space-around;

  gap: 0.5em;
  h1 {
    line-height: 1.3;
    margin: 0;
  }
  display: flex;
  flex-direction: column;
  @media screen and (max-width: 800px) {
    border-radius: 50px;
    align-items: center;
    h1 {
      font-size: 1.5em;
      text-align: center;
    }
  }
`;
const Socials = styled.div`
  display: flex;
  gap: 1em;
`;

const IconBlockContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1em;
`;

function IconBlock(props: { icon: IconProp; children?: React.ReactNode }) {
  return (
    <IconBlockContainer>
      <FontAwesomeIcon icon={props.icon} size="2x" />
      <p>{props.children}</p>
    </IconBlockContainer>
  );
}

export function ContactInfo() {
  const [{ data }] = useDescriptiveBlockQuery({
    variables: { id: "2SUamiiWua4LGkWaOOnriR" },
  });

  return (
    <Container>
      <h1>
        IL NOLEGGIO <br />
        SU MISURA PER TE
      </h1>
      {documentToReactComponents(data?.descriptiveBlock?.description?.json)}
      <IconBlock icon={faPhone}>
        <b>Telefono e Whatsapp</b>
        <br />
        <a href="tel:+393319686663">+39 331 9686663</a>
      </IconBlock>
      <IconBlock icon={faEnvelope}>
        <b>Email</b>
        <br />
        <a href="mailto:info@automotive-services.it">
          info@automotive-services.it
        </a>
      </IconBlock>
      <Socials>
        <a href="https://www.facebook.com/automotiveservices.it">
          <FontAwesomeIcon icon={faFacebook} size="2x" />
        </a>
        <a href="https://www.instagram.com/matteonoleggioauto/">
          <FontAwesomeIcon icon={faInstagram} size="2x" />
        </a>

        <a href="https://api.whatsapp.com/send?phone=393319686663">
          <FontAwesomeIcon icon={faWhatsapp} size="2x" />
        </a>
      </Socials>
    </Container>
  );
}
