import { Tab, TabList } from "@fluentui/react-components";

import styled from "styled-components";
import { BrandList } from "./brandList/brandslist";
import { BrandProvider } from "./brandList/brandListProvider";
import { useState } from "react";
import { AutoEditor } from "./autoEditor/autoEditor";
import { AutoEditorProvider } from "./autoEditor/autoEditorProvider";
import { useAdminQuery } from "../../generated/graphql";

const LeftContainer = styled.div`
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.14);
`;
const RightContainer = styled(LeftContainer)`
  padding: 20px;
`;

const MainContainer = styled.div`
  display: grid;
  height: 100vh;
  grid-column-gap: 20px;
  grid-template-columns: 150px 1fr;
`;

export function Manager() {
  const [selectedTab, setSelectedTab] = useState<"auto" | "brands">("brands");
  const [{ error }] = useAdminQuery();

  if (error) {
    window.location.href = "/login";
  }
  return (
    <MainContainer>
      <LeftContainer>
        <TabList
          vertical
          selectedValue={selectedTab}
          onTabSelect={(event, data) => setSelectedTab(data.value as any)}
        >
          <Tab value="brands">Brands</Tab>
          <Tab value="auto">Auto</Tab>
        </TabList>
      </LeftContainer>
      <RightContainer>
        <BrandProvider>
          <AutoEditorProvider>
            {selectedTab === "brands" && <BrandList />}
            {selectedTab === "auto" && <AutoEditor />}
          </AutoEditorProvider>
        </BrandProvider>
      </RightContainer>
    </MainContainer>
  );
}
