import { styled } from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebook,
  faInstagram,
  faWhatsapp,
} from "@fortawesome/free-brands-svg-icons";
import { faPhone, faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { useDescriptiveBlockQuery } from "../generated/graphql";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
const IconBlockContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-self: center;
  justify-self: end;
  align-items: center;
  gap: 1em;
`;

const Socials = styled.div`
  grid-column: 3/4;
  display: flex;
  gap: 1em;
  justify-self: end;
  @media screen and (max-width: 700px) {
    grid-column: span 1;
  }
`;
const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: black;
  padding: 2em;
`;
const AllItaly = styled.div`
  width: 100%;
  grid-column: span 3;

  font-weight: bold;
`;
const LastRow = styled.p`
  grid-column: span 3;
  text-align: center;

  white-space: break-spaces;
`;
const Container = styled.div`
  width: 100%;
  max-width: 700px;
  display: grid;
  color: white;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 50px 30px 1fr;
  grid-row-gap: 1em;

  & > img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
  & > ${IconBlockContainer}:nth-of-type(3) {
    justify-self: center;
  }
  @media screen and (max-width: 700px) {
    grid-template-columns: 1fr;
    justify-items: center;
    grid-row-gap: 0.5em;
    grid-template-rows: 50px auto auto auto auto auto auto;
    ${AllItaly}, & > img ,${LastRow},${Socials},${IconBlockContainer},p {
      grid-column: span 1;
      justify-self: center;
      text-align: center;
    }
  }
`;

export function IconBlock(props: {
  icon: IconProp;
  children?: React.ReactNode;
}) {
  return (
    <IconBlockContainer>
      <FontAwesomeIcon icon={props.icon} size="2x" />
      <p>{props.children}</p>
    </IconBlockContainer>
  );
}
export function Footer() {
  const [{ data }] = useDescriptiveBlockQuery({
    variables: { id: "4NxiTe1lszMwdvJcFGkTkz" },
  });
  return (
    <Wrapper>
      <Container>
        <img src="/img/logoblack.webp"></img>

        <Socials>
          <a href="https://www.facebook.com/automotiveservices.it">
            <FontAwesomeIcon icon={faFacebook} size="2x" />
          </a>
          <a href="https://www.instagram.com/matteonoleggioauto/">
            <FontAwesomeIcon icon={faInstagram} size="2x" />
          </a>

          <a href="https://api.whatsapp.com/send?phone=393319686663">
            <FontAwesomeIcon icon={faWhatsapp} size="2x" />
          </a>
        </Socials>
        <AllItaly>Servizio disponibile in tutta Italia.</AllItaly>
        <p>
          {documentToReactComponents(data?.descriptiveBlock?.description?.json)}
        </p>
        <IconBlock icon={faPhone}>
          <b>Telefono e Whatsapp</b>
          <br />
          <a href="tel:+393319686663">Matteo: +39 331 9686663</a>
          <a href="tel:+393319686663">Loris: +39 340 308 6394</a>
        </IconBlock>
        <IconBlock icon={faEnvelope}>
          <b>Email</b>
          <br />
          <a href="mailto:info@automotive-services.it">
            info@automotive-services.it
          </a>
        </IconBlock>
        <LastRow>
          {`© 2024 Automotive Services di Matteo Prearo - P.IVA 12272090965
 Tutti i diritti riservati. `}
          <a href="https://www.iubenda.com/privacy-policy/25516727">
            Informativa sulla privacy
          </a>
          <a href="https://www.iubenda.com/privacy-policy/25516727/cookie-policy">
            Informativa sui cookies
          </a>
        </LastRow>
      </Container>
    </Wrapper>
  );
}
