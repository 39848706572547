import { Button, Input, Label, Select } from "@fluentui/react-components";
import { useAutoEditor, AutoEditorContextType } from "./autoEditorProvider";
import { Selector } from "./SelectModel";
import styled from "styled-components";
import { useBrandList } from "../brandList/brandListProvider";
import { Tractions_Enum } from "../../../generated/graphql";
import { CheckBox, FormInputs, SelectInput, TextInput } from "./common";
import { PricesList } from "./pricesList";
import { useForm, SubmitHandler, FormProvider } from "react-hook-form";
import { NewCar } from "./newCar";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import FileUpload from "./picsUploader";
import { PicUpload } from "./newPicUpload";

const Container = styled.div`
  margin-top: 3em;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  grid-column-gap: 1rem;
  max-width: 800px;
`;

export function AutoEditor() {
  return (
    <>
      <Selector />
      <BasicDetails />
    </>
  );
}

export function BasicDetails() {
  const { modelDetail, updateModel, selectedModel, deleteModel } =
    useAutoEditor();
  const { brandList } = useBrandList();

  const tractions = Object.entries(Tractions_Enum);
  const methods = useForm<FormInputs>({
    defaultValues: {
      model_name: modelDetail?.model_name,
      brand: modelDetail?.brand,
      engine: modelDetail?.engine,
      traction_type: modelDetail?.traction_type,
      furgone: modelDetail?.furgone,
      imgs: modelDetail?.imgs || [],
      prices:
        modelDetail?.prices.map(({ __typename, ...keepAttrs }) => keepAttrs) ||
        [],
    },
  });
  const { handleSubmit, control, reset, register, setValue, getValues } =
    methods;
  const onSubmit: SubmitHandler<FormInputs> = (data) => {
    updateModel(data).then((val) => {});
  };
  useEffect(() => {
    reset({
      model_name: modelDetail?.model_name,
      brand: modelDetail?.brand,
      engine: modelDetail?.engine,
      imgs: modelDetail?.imgs || [],
      furgone: modelDetail?.furgone,
      traction_type: modelDetail?.traction_type,
      prices:
        modelDetail?.prices.map(({ __typename, ...keepAttrs }) => keepAttrs) ||
        [],
    });
  }, [
    modelDetail?.brand,
    modelDetail?.engine,
    modelDetail?.model_name,
    modelDetail?.traction_type,
    modelDetail?.furgone,
    modelDetail?.imgs,
    reset,
    selectedModel,
    modelDetail?.prices,
  ]);

  if (!selectedModel || selectedModel === "seleziona") return <NewCar />;

  return (
    <>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Container>
            <div>
              <SelectInput
                control={control}
                displayName="Marca"
                value={modelDetail?.brand}
                name="brand"
                options={brandList.map((val) => (
                  <option key={val.name}>{val.name}</option>
                ))}
              />
            </div>

            <div>
              <TextInput
                displayName="Modello"
                name="model_name"
                value={modelDetail?.model_name}
                control={control}
              />
            </div>
            <div>
              <TextInput
                displayName="Motore"
                name="engine"
                value={modelDetail?.engine}
                control={control}
              />
            </div>
            <div>
              <SelectInput
                control={control}
                displayName="Trazione"
                value={modelDetail?.traction_type}
                name="traction_type"
                options={tractions.map(([val, key]) => (
                  <option key={val} value={key}>
                    {val}
                  </option>
                ))}
              />
            </div>
            <div>
              <label htmlFor="furgone"> Furgone</label>

              <input type="checkbox" id="furgone" {...register("furgone")} />
            </div>
          </Container>

          <PricesList />
          <br></br>
          <PicUpload
            default={getValues("imgs")}
            onChange={(val) => setValue("imgs", val)}
          />
          <br></br>
          <Button type="submit">Salva</Button>
          <Button appearance="secondary" onClick={() => deleteModel()}>
            Elimina
          </Button>
          <br></br>
          <br></br>
          <Link to={"/carprice/" + modelDetail?.id}>Vai alla macchina</Link>
        </form>
      </FormProvider>
    </>
  );
}
