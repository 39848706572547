import styled from "styled-components";

const Container = styled.div`
  display: flex;
  flex-direction: row;
  background-color: #00000042;
  padding: 2em;
  justify-content: center;
  gap: 2em;
  @media screen and (max-width: 700px) {
    flex-direction: column;
    align-items: center;
  }
`;
const Icon = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  font-weight: bold;
  max-width: 200px;
  img {
    margin-bottom: 1em;
    height: 150px;
    width: 200px;
    object-fit: contain;
    object-position: center;
  }
`;

export function FurgoniIcons() {
  return (
    <Container>
      <Icon>
        <img src="/img/furgonIcons/risparmio.webp" />
        <div>SPESA FISSA E NESSUN IMPREVISTO</div>
      </Icon>
      <Icon>
        <img src="/img/furgonIcons/tutela.webp" />
        <div>TUTELA TE E I TUOI AUTISTI</div>
      </Icon>
      <Icon>
        <img src="/img/furgonIcons/tempo.webp" />
        <div>PIÚ TEMPO PER IL TUO LAVORO</div>
      </Icon>
    </Container>
  );
}
