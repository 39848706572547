import styled, { css } from "styled-components";
import { WrapContainer } from "./wrapContainer";
import { useState } from "react";
import { useGetQaQuery } from "../generated/graphql";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";

const Container = styled.div`
  max-width: var(--page-max-width);
  width: 100%;
  padding-top: 2em;
  padding-bottom: 2em;
`;

const AccordionTitle = styled.div`
  font-size: 1.5em;
  border-bottom: 1px solid black;
  padding-bottom: 0.5em;
  display: flex;
  justify-content: space-between;
  padding-right: 1em;
  cursor: pointer;
`;
const Arrow = styled.div`
  font-size: 1.5em;
  transition: transform 0.5s;
`;
const AccordionContainer = styled.div<{ $active?: boolean }>`
  p {
    overflow: hidden;
    transition: max-height 0.5s;
  }

  ${(props) =>
    !props.$active &&
    css`
      p {
        max-height: 0px;
      }
    `}

  ${(props) =>
    props.$active &&
    css`
      p {
        max-height: 500px;
      }
      ${Arrow} {
        transform: rotate(90deg);
      }
    `}
`;

function AccordionRow(props: { title: string; description: React.ReactNode }) {
  const [active, setActive] = useState(false);
  return (
    <AccordionContainer $active={active} onClick={() => setActive(!active)}>
      <AccordionTitle>
        {props.title}
        <Arrow>›</Arrow>
      </AccordionTitle>
      {props.description}
    </AccordionContainer>
  );
}
export function Accordion() {
  const [{ data }] = useGetQaQuery();
  return (
    <WrapContainer>
      <Container>
        <h1>Q&A</h1>
        {data?.descriptiveBlockCollection?.items.map((item) => (
          <AccordionRow
            key={item?.title}
            title={item?.title || ""}
            description={documentToReactComponents(item?.description?.json)}
          />
        ))}
      </Container>
    </WrapContainer>
  );
}
