import styled from "styled-components";

export const WrapContainer = styled.div<{
  grey?: boolean;
  red?: boolean;
  black?: boolean;
}>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0em 4em;
  background-color: ${(props) => (props.grey ? "#d9d9d936" : "white")};
  background-color: ${(props) => props.red && "#be1522"};
  background-color: ${(props) => props.black && "black"};
  color: ${(props) => props.red && "white"};

  @media screen and (max-width: 500px) {
    padding: 1em;
  }
`;
