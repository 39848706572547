import styled from "styled-components";
import { DescriptiveBlockWrapped } from "../../components/descriptiveBlock";
import { FurgoniIcons } from "./furgoniIcons";
import { NewsLetter } from "../../components/newsLetter";
import { MonthlyOffers } from "../home/monthlyOffers";
import { RoundedInfo } from "../../components/roundedInfo";

const ImgBanner = styled.img`
  width: 100%;
  height: auto;
`;

export function Furgoni() {
  return (
    <>
      <ImgBanner src="/img/furgoni.webp"></ImgBanner>
      <DescriptiveBlockWrapped id="M3QHAiKAKZcaQUdhrf2Xk" />
      <FurgoniIcons />
      <MonthlyOffers id="7nZE1vjk1hSdsGyWpb7c8G" size={3} van />
      <RoundedInfo id="2XKs2NYcToqGFcpTB9U3Yz"></RoundedInfo>
      <NewsLetter />
    </>
  );
}
