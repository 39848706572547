import styled from "styled-components";

export const RoundedButton = styled.button`
  text-align: center;
  text-transform: uppercase;
  border: none;
  background-color: transparent;
  font-size: 22;
  border-radius: 20px;
  padding: 10px 20px;
  border: 2px solid #be1522;
  color: #be1522;
  max-width: 300px;
  cursor: pointer;
  &.solid {
    background-color: #be1522;
    color: white;
  }
  &.white {
    border: 2px solid white;
    color: white;
  }
  &:hover {
    background-color: #be1522;
    color: white;
  }
  &:disabled {
    cursor: auto;
    opacity: 0.5;
  }
  &.hide-mobile {
    @media screen and (max-width: 400px) {
      display: none;
    }
  }
`;
