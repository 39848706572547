import { MonthlyOffers } from "../home/monthlyOffers";
import { PreventivoForm } from "./preventivoform";

export function Preventivo() {
  return (
    <>
      <PreventivoForm />
      <MonthlyOffers id="7nZE1vjk1hSdsGyWpb7c8G" size={3} />
    </>
  );
}
