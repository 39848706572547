import styled from "styled-components";
import { CardGrid } from "../../components/carGrid";
import { WrapContainer } from "../../components/wrapContainer";
import { CarCard } from "../../components/carCard";
import {
  SearchOffersQueryVariables,
  SearchQueryVariables,
  useSearchOffersQuery,
  useSearchQuery,
} from "../../generated/graphql";
import { useEffect, useState } from "react";
import Dropdown from "../../components/dropDown";
const Container = styled.div`
  width: 100%;

  max-width: var(--page-max-width);
`;

function SearchBar(props: { onSelect: (arg1: SearchQueryVariables) => void }) {
  const [searchParam, setSearchParam] = useState<SearchQueryVariables>({
    furgone: false,
    brand: undefined,
  });
  const [{ data }] = useSearchQuery({ variables: searchParam });
  useEffect(() => {
    props.onSelect(searchParam);
  }, [searchParam, props]);
  return (
    <>
      <Dropdown
        placeholder="Auto/Furgone"
        value={searchParam.furgone ? "true" : "false"}
        onChange={(event) => {
          setSearchParam({
            brand: undefined,
            furgone: event.target.value === "true",
          });
        }}
      >
        <option key="vanfalse" value="true">
          Furgone
        </option>
        <option key="vantrue" value="false">
          Auto
        </option>
      </Dropdown>
      <Dropdown
        placeholder="Scegli la marca"
        value={searchParam.brand || "placeholder"}
        onChange={(event) => {
          setSearchParam({
            ...searchParam,
            brand: event.target.value,
          });
        }}
      >
        <option key={"Tutte"} value={undefined}>
          Tutte
        </option>
        {data?.brands.map((brand) => (
          <option key={"brand" + brand.name} value={brand.name}>
            {brand.name}
          </option>
        ))}
      </Dropdown>
    </>
  );
}

const SearchBarContainer = styled.div`
  display: flex;
  gap: 2em;

  margin-top: 2em;
  margin-bottom: 2em;
  @media screen and (max-width: 700px) {
    flex-direction: column;
    align-items: center;
  }
`;
export function AllOffersGrid() {
  const [searchParam, setSearchParam] = useState<SearchQueryVariables>({
    furgone: false,
    brand: undefined,
  });
  const [{ data }, refresh] = useSearchOffersQuery({
    variables: genSearchQuery(),
  });

  function genSearchQuery() {
    const searchQuery: SearchOffersQueryVariables = {
      where: { furgone: { _eq: searchParam.furgone } },
    };
    if (searchParam.brand && searchParam.brand !== "Tutte") {
      if (searchQuery.where) {
        searchQuery.where.brand = { _eq: searchParam.brand };
      }
    }

    return searchQuery;
  }
  return (
    <WrapContainer>
      <Container>
        <SearchBarContainer>
          <SearchBar onSelect={(val) => setSearchParam(val)} />
        </SearchBarContainer>
        <CardGrid>
          {data?.models.map((car) => (
            <CarCard id={car.id} />
          ))}
        </CardGrid>
      </Container>
    </WrapContainer>
  );
}
