import { Select } from "@fluentui/react-components";
import { useAutoEditor } from "./autoEditorProvider";

export function Selector() {
  const { models, selectedModel, setSelectedModel } = useAutoEditor();
  return (
    <>
      <label htmlFor={"seleModel"}>Modello</label>
      <Select
        id={"seleModel"}
        onChange={(even, data) => setSelectedModel(data.value)}
        value={selectedModel}
        defaultValue="seleziona"
      >
        <option value="seleziona">Nuova macchina</option>

        {models.map((val) => (
          <option
            key={val.id}
            value={val.id}
          >{`${val.brand} ${val.model_name} ${val.engine}`}</option>
        ))}
      </Select>
    </>
  );
}
