import React from "react";
import styled from "styled-components";

const Select = styled.select`
  width: 100%;
  padding: 10px;
  font-size: 16px;
  border: 1px solid #868585;

  appearance: none;
  background: white;
  background-image: url('data:image/svg+xml;charset=US-ASCII,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 4 5"><path fill="%23aaa" d="M2 0L0 2h4zM0 3l2 2 2-2z"/></svg>');
  background-repeat: no-repeat;
  background-position: right 10px center;
  background-size: 10px;

  font-family: "Open Sans";
  font-style: normal;
  font-weight: 300;
  height: 45px;
  max-width: 300px;
`;

function Dropdown(props: {
  placeholder: string;
  children?: React.ReactNode;
  onChange?: (e: React.ChangeEvent<HTMLSelectElement>) => void;
  value?: string;
}) {
  return (
    <Select onChange={props.onChange} value={props.value}>
      <option value="placeholder" disabled>
        {props.placeholder}
      </option>
      {props.children}
    </Select>
  );
}
export default Dropdown;
