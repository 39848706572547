import styled from "styled-components";

export const Input = styled.input`
  border-radius: 20px;
  border-color: #868585;
  border-width: 1px;
  border-style: solid;
  padding: 1em;
`;

export const TextArea = styled.textarea`
  font-family: inherit;
  border-radius: 20px;
  border-color: #868585;
  border-width: 1px;
  border-style: solid;
  padding: 1em;
  box-sizing: border-box;
  width: 100%;
  min-height: 150px;
`;
