import styled from "styled-components";
import { WrapContainer } from "../../components/wrapContainer";
import { useFiscoQuery } from "../../generated/graphql";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";

const Container = styled.div`
  width: 100%;

  max-width: var(--page-max-width);
  border-top: 1px solid black;
  padding-top: 2em;
  padding-bottom: 2em;
  h1 {
    line-height: 1.3em;
  }
`;
const Row = styled.div`
  display: contents;
  div {
    background-color: inherit;
    padding: 0.5em;
  }
`;
const TableContainer = styled.div`
  display: grid;
  grid-template-columns: auto auto auto;

  text-align: center;
  ${Row}:nth-child(odd) {
    background-color: #d9d9d9;
  }
  ${Row}:nth-child(even) {
    background-color: #d9d9d97f;
  }
  @media screen and (max-width: 500px) {
    grid-template-columns: auto;
    text-align: left;
  }
`;
const Header = styled.div`
  display: contents;

  div {
    font-weight: bold;
    align-self: center;
  }
`;

const LeftTitle = styled.div`
  text-align: left;
`;

const Detraction = styled.div`
  color: #be1522;
  font-weight: bold;
`;
export function FiscoTable() {
  const [{ data }] = useFiscoQuery();
  const fisco = data?.fiscoCollection?.items;
  return (
    <WrapContainer>
      <Container>
        <TableContainer>
          <div></div>
          <Header>
            <div>Detrazione IVA</div>
            <div>Deduzione imposte dirette</div>
          </Header>
          {fisco?.map((f) => (
            <Row>
              <LeftTitle>
                <p>
                  <b>{f?.titolo} </b>
                </p>
              </LeftTitle>
              <Detraction>{f?.detrazione}</Detraction>

              <div>{documentToReactComponents(f?.descrizione?.json)}</div>
            </Row>
          ))}
        </TableContainer>
      </Container>
    </WrapContainer>
  );
}
