import styled from "styled-components";
import { CarCard } from "../../components/carCard";
import { CardGrid } from "../../components/carGrid";
import { DescriptiveBlockWrapped } from "../../components/descriptiveBlock";
import { RoundedButton } from "../../components/roundedButton";
import { useDescriptiveBlockQuery } from "../../generated/graphql";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { useModelsCardsQuery } from "../../generated/graphql";
const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1em;
  justify-content: center;
  margin-top: 1em;
  margin-bottom: 2em;
  @media screen and (max-width: 700px) {
    flex-direction: column;
    align-items: center;
    ${RoundedButton} {
      width: 100%;
    }
  }
`;
const Container = styled.div`
  width: 100%;

  max-width: var(--page-max-width);
`;

function Cards(props: { limit: number; van?: boolean }) {
  const [{ data }] = useModelsCardsQuery({
    variables: { limit: props.limit, furgone: props.van ? { _eq: true } : {} },
  });
  return (
    <Container>
      <CardGrid>
        {data?.models.map((item, index) => (
          <CarCard key={index} id={item?.id} />
        ))}
      </CardGrid>
    </Container>
  );
}

export function MonthlyOffers(props: {
  id: string;
  size: number;
  van?: boolean;
}) {
  const [{ data }] = useDescriptiveBlockQuery({
    variables: { id: props.id },
  });

  return (
    <DescriptiveBlockWrapped grey id={props.id}>
      <CardGrid>
        <Cards limit={props.size} van={props.van} />
      </CardGrid>
      {documentToReactComponents(
        data?.descriptiveBlock?.secondDescription?.json
      )}
      <ButtonsContainer>
        <a href="/offerte">
          <RoundedButton>SCOPRI TUTTE LE OFFERTE</RoundedButton>
        </a>
        <a href="/preventivo">
          <RoundedButton className="solid">
            RICHIEDI UN PREVENTIVO
          </RoundedButton>
        </a>
      </ButtonsContainer>
    </DescriptiveBlockWrapped>
  );
}
