import { Accordion } from "../../components/accordionInfo";
import { NewsLetter } from "../../components/newsLetter";
import { ContactForm } from "./contactForm";

export function Contacts() {
  return (
    <>
      <ContactForm />

      <Accordion />
      <NewsLetter />
    </>
  );
}
