import styled from "styled-components";

const Container = styled.div`
  display: flex;
  flex-direction: row;
  background-color: #00000042;
  padding: 2em;
  justify-content: center;
  gap: 2em;
  @media screen and (max-width: 700px) {
    flex-direction: column;
    align-items: center;
  }
`;
const Icon = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  font-weight: bold;
  max-width: 200px;
  img {
    margin-bottom: 1em;
    height: 150px;
    width: 200px;
    object-fit: contain;
    object-position: center;
  }
`;

export function HomeIcons() {
  return (
    <Container>
      <Icon>
        <img src="/homeIcons/newcar.webp" />
        <div>AUTO SEMPRE NUOVA</div>
      </Icon>
      <Icon>
        <img src="/homeIcons/cheap.webp" />
        <div>SPESE FISSE PER LA TUA MOBILITÀ</div>
      </Icon>
      <Icon>
        <img src="/homeIcons/inclusive.webp" />
        <div>CANONE TUTTO INCLUSO</div>
      </Icon>
    </Container>
  );
}
