import styled from "styled-components";
import { WrapContainer } from "../../components/wrapContainer";
import { Input, TextArea } from "../../components/Input";
import { ContactInfo } from "./contactsInfo";
import { RoundedButton } from "../../components/roundedButton";
import { SubmitHandler, useForm } from "react-hook-form";
import {
  SendContactEmailMutationVariables,
  useDescriptiveBlockQuery,
  useSendContactEmailMutation,
} from "../../generated/graphql";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";

const Container = styled.div<{ center?: boolean }>`
  max-width: var(--page-max-width);
  width: 100%;
  display: flex;
  padding-top: 2em;
  padding-bottom: 2em;
  gap: 2em;
  justify-content: space-between;
  align-items: center;
  @media screen and (max-width: 800px) {
    flex-direction: column;
  }
`;

const ContactInput = styled(Input)`
  width: 100%;
  box-sizing: border-box;
`;
const Form = styled.form`
  max-width: 500px;
  display: flex;
  flex-direction: column;
  gap: 1em;
`;
const Submit = styled(RoundedButton)`
  width: 120px;
  align-self: flex-end;
  margin-top: 1em;
`;

export function ContactForm() {
  const [_response, sendEmail] = useSendContactEmailMutation();
  const methods = useForm<SendContactEmailMutationVariables>();

  const [{ data }] = useDescriptiveBlockQuery({
    variables: { id: "5F18NY0c82NuHcImzmr04c" },
  });

  const { register, handleSubmit, watch } = methods;
  const onSubmit: SubmitHandler<SendContactEmailMutationVariables> = async (
    data
  ) => {
    console.log(data);
    await sendEmail(data);
  };

  return (
    <WrapContainer grey>
      <Container>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <h1>CONTATTI</h1>
          {documentToReactComponents(data?.descriptiveBlock?.description?.json)}

          <ContactInput placeholder="NOME *" {...register("name")} required />
          <ContactInput
            placeholder="COGNOME *"
            {...register("surname")}
            required
          />
          <ContactInput
            type="email"
            placeholder="EMAIL *"
            {...register("email")}
            required
          />
          <ContactInput
            placeholder="TELEFONO *"
            type="tel"
            {...register("phone")}
            required
          />
          <ContactInput placeholder="PARTITA IVA" {...register("vat")} />
          <TextArea
            placeholder="MESSAGGIO *"
            {...register("content")}
            required
          />

          {_response.data ? (
            <p>Grazie per averci contattato</p>
          ) : (
            <Submit className="solid" type="submit">
              Invia
            </Submit>
          )}
        </Form>
        <ContactInfo />
      </Container>
    </WrapContainer>
  );
}
