import styled from "styled-components";

export const CardGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(360px, 1fr));
  row-gap: 50px;
  column-gap: 0;
  justify-items: center;
  padding: 2em;
  @media screen and (max-width: 550px) {
    grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
    padding: 0;
  }
`;
