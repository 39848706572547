import styled from "styled-components";
import { useDescriptiveBlockQuery } from "../../generated/graphql";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";

const Container = styled.div`
  width: 100%;
  max-width: var(--page-max-width);
  margin: 1em;
  padding: 1em;
  margin-bottom: 2em;
  h1 {
    line-height: 1.3em;
  }
`;
const ContainerIcon = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1em;
  max-width: 300px;
  p {
    margin: 0;
    padding: 0;
  }
  img {
    width: 50px;

    height: 100%;
    max-height: 50px;
    object-fit: contain;
  }
  @media screen and (max-width: 900px) and (min-width: 650px) {
    flex-direction: column;
    align-items: center;
    text-align: center;
    max-width: 200px;
  }
  @media screen and (max-width: 650px) {
    width: 100%;
  }
`;
const IconRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  padding-top: 1em;
  gap: 1em;
  @media screen and (max-width: 650px) {
    flex-direction: column;
    align-items: center;
  }
`;
function Icon(props: { icon: string; id: string }) {
  const [{ data }] = useDescriptiveBlockQuery({ variables: { id: props.id } });

  return (
    <ContainerIcon>
      <img src={props.icon} />
      <div>
        <b>{data?.descriptiveBlock?.title}</b>
        <br />
        {documentToReactComponents(data?.descriptiveBlock?.description?.json)}
      </div>
    </ContainerIcon>
  );
}
export function CarIcons() {
  return (
    <Container>
      <h1>SERVIZI INCLUSI NEL NOLEGGIO</h1>
      <IconRow>
        <Icon
          id="7F3OJCpoU2loVkB3I0qV7o"
          icon="/iconeMacchina/manutenzione.webp"
        />
        <Icon id="sHSQoMzb3UV88M6ZjWpLF" icon="/iconeMacchina/soccorso.webp" />
        <Icon
          id="2w1NxGkuMSEqkVMdFZ0mEs"
          icon="/iconeMacchina/assicurazione.webp"
        />
        <Icon
          id="1507Mu11LKenjfDPNjPyl4"
          icon="/iconeMacchina/assistenza.webp"
        />
      </IconRow>
    </Container>
  );
}
