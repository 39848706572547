import styled from "styled-components";
import { PriceSelector } from "../priceSelector/priceSelector";
import { CarPics } from "./carpics";
import { CarInfo } from "./carInfo";
import { RoundedInfo } from "../../components/roundedInfo";
import { CarIcons } from "./icons";
import { WrapContainer } from "../../components/wrapContainer";
import { OtherOffers } from "./otherOffers";
import { Preventivo } from "../preventivo/preventivo";
import { PreventivoForm } from "../preventivo/preventivoform";

const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;

  justify-items: start;
  align-items: center;
  width: 100%;
  max-width: var(--page-max-width);
  grid-column-gap: 2em;

  @media screen and (max-width: 900px) {
    grid-template-columns: 1fr;
    align-items: stretch;
    justify-items: center;
  }
`;

export function CarPage() {
  return (
    <>
      <WrapContainer>
        <Container>
          <CarPics></CarPics>
          <PriceSelector />
        </Container>
        <CarInfo />
        <CarIcons />
      </WrapContainer>
      <OtherOffers />
      <RoundedInfo id="21NuzKUlTlmkOW25gjGsTi" />
      <PreventivoForm />
    </>
  );
}
