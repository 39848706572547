import styled from "styled-components";
import { DescriptiveBlockWrapped } from "../../components/descriptiveBlock";
import { NewsLetter } from "../../components/newsLetter";
import { RentTable } from "./rentTable";
import { Advantages } from "./advantages";
import { MonthlyOffers } from "../home/monthlyOffers";
import { Videos } from "./videos";
import { Accordion } from "../../components/accordionInfo";

const ImgBanner = styled.img`
  width: 100%;
  height: auto;
`;

export function WhatIsRent() {
  return (
    <>
      <ImgBanner src="/img/cover1.webp"></ImgBanner>
      <DescriptiveBlockWrapped id="3TlyHoAbjGXGP5g404x7Nd" />
      <RentTable />
      <Advantages />
      <MonthlyOffers id="7nZE1vjk1hSdsGyWpb7c8G" size={3} />
      <Videos />
      <Accordion></Accordion>
      <NewsLetter />
    </>
  );
}
